<template>
    <div style="display: inline-block">

        <!-- Modal -->
        <div class="modal fade" id="searchCatalogDialog" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <form class="modal-content" @submit.prevent="" >
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Adicionar Produto à Catálogo</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Fechar"></button>
                    </div>
                    <div class="modal-body" >
                        <Pagination ref="pagination" v-on:page-clicked="(c) => this.catalogs = c" endpoint-list="/catalog/list/" endpoint-count="/catalog/count" v-bind:search-at-start="false" />
                        <div class="mb-1" style="max-height: 380px; overflow-y: auto">
                            <div class="row" v-for="catalog in catalogs" :key="catalog.id" style="display: inline; height: 1rem; margin: 0">
                                <div class="form-check" style="height: 1rem; margin: 0">
                                    <input type="checkbox" :checked="listContainsCatalog(catalog)" class="form-check-input" @click="switchSelectedCatalog(catalog)">
                                    <label class="form-check-label">{{ catalog.name }}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div style="width: 100%">
                            <button type="button" class="btn btn-primary" data-bs-dismiss="modal" style="float: left" @click="include">Adicionar</button>
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" style="float: right">Fechar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import Pagination from '@/components/pages/components/Pagination.vue'
import axios from "axios";

export default {

    components: {
        Pagination
    },

    data: function () {
        return {
            catalogs: [],
            selectedCatalogs: []
        }
    },

    props: {
        products: {
            type: Array
        }
    },

    methods: {
        include() {
            let catalogIds = []
            this.selectedCatalogs.forEach(c => {
                catalogIds.push(c.id)
            })
            let url =this.$API_ADDRESS + '/catalog/includeProducts?catalogList=' + encodeURI(JSON.stringify(catalogIds))
                + '&productList=' + encodeURI(JSON.stringify(this.products))
            axios.post(url)
                .then(response => {
                    if(response.status === 201){
                        this.showMessage('Produtos Adicionados!', 'INFO');
                    }
                })
        },

        switchSelectedCatalog(catalog) {
            if (this.selectedCatalogs.indexOf(catalog) < 0) {
                this.selectedCatalogs.push(catalog)
            } else {
                this.selectedCatalogs.splice(this.selectedCatalogs.indexOf(catalog), 1)
            }
        },

        listContainsCatalog(catalog) {
            return this.selectedCatalogs.indexOf(catalog) >= 0;
        }
    }
}
</script>

<style scoped>

</style>