<template>
    <div>
        <MenuBar/>
        <div class="contentOnNavBar">
            <SideMenu/>
            <div style="padding: 0.5rem; width: 100%" v-if="client">
                <div class="pageTitle" v-if="client.id === 0">Cadastrar Cliente</div>
                <div class="pageTitle" v-if="client.id !== 0">Atualizar Cliente</div>
                <div class="pageTitleDetails">Insira abaixo as informações do Cliente. Campos marcados com "*" são obrigatórios.</div>

                <form class="row pt-2" style="margin-left: 0; margin-right: 0;" @submit.prevent="saveOrUpdate">
                    <div class="col-sm-6 col-lg-4 col-xl-3 mb-1">
                        <label>Nome Completo *</label>
                        <input v-model="client.name" type="text" required class="form-control form-control-sm"/>
                    </div>

                    <div class="col-sm-6 col-lg-4 col-xl-3 mb-1">
                        <label>Email *</label>
                        <input v-model="client.email" type="email" required class="form-control form-control-sm"/>
                    </div>

                    <div class="col-sm-6 col-lg-4 col-xl-3 mb-1">
                        <label>Tipo</label><br/>
                        <SelectPersonType :name="'personType'" v-model="client.personType"/>
                    </div>

                    <div class="col-sm-6 col-lg-4 col-xl-3 mb-1">
                        <label>Status</label><br/>
                        <SelectPersonStatus :name="'personStatus'" v-model="client.status"/>
                    </div>

                    <div class="col-sm-6 col-lg-4 col-xl-3 mb-1" v-if="client.personType == 'FISICAL_PERSON'">
                        <label>CPF</label>
                        <input v-model="client.cpf" type="text" class="form-control form-control-sm" v-mask="'###.###.###-##'"/>
                    </div>

                    <div class="col-sm-6 col-lg-4 col-xl-3 mb-1" v-if="client.personType == 'FISICAL_PERSON'">
                        <label>RG</label>
                        <input v-model="client.cpf" type="text" class="form-control form-control-sm"/>
                    </div>

                    <div class="col-sm-6 col-lg-4 col-xl-3 mb-1" v-if="client.personType == 'LEGAL_PERSON'">
                        <label>CNPJ</label>
                        <input v-model="client.cnpj" type="text" class="form-control form-control-sm" v-mask="'##.###.###/####-##'"/>
                    </div>

                    <div class="col-sm-6 col-lg-4 col-xl-3 mb-1" v-if="client.personType == 'FOREIGNER'">
                        <label>Passaporte</label>
                        <input v-model="client.passport" type="text" class="form-control form-control-sm"/>
                    </div>

                    <div class="col-sm-6 col-lg-4 col-xl-3 mb-1">
                        <label>Telefone</label>
                        <input v-model="client.commercialPhone" type="text" class="form-control form-control-sm" v-mask="'(##) ####-####'"/>
                    </div>

                    <div class="col-sm-6 col-lg-4 col-xl-3 mb-1">
                        <label>Telefone Celular</label>
                        <input v-model="client.privatePhone" type="text" class="form-control form-control-sm" v-mask="'(##) #####-####'"/>
                    </div>

                    <div class="col-sm-6 col-lg-4 col-xl-3 mb-1">
                        <label>Site</label>
                        <input v-model="client.site" type="text" class="form-control form-control-sm"/>
                    </div>


                    <div>
                        <div class="pageSubTitle mb-3">Endereços</div>
                    </div>
                    <div style="font-size: 1rem; margin-bottom: 0.5rem;">
                        <button type="button" class="btn btn-primary btn-sm" @click="client.addresses.push({})">Novo Endereço</button>
                    </div>
                    <div v-if="client.addresses">
                        <div v-for="(address, index) in client.addresses" :key="index" style="border-bottom: 1px solid #ccc; padding-bottom: 0.5rem" class="row">
                            <div class="col-sm-6 col-lg-4 col-xl-3 mb-1">
                                <label>Logradouro</label>
                                <input v-model="address.street" type="text" class="form-control form-control-sm" placeholder="Ex.: Rua Rothbard, Av. Hoppe..."/>
                            </div>
                            <div class="col-sm-6 col-lg-2 col-xl-2 mb-1">
                                <label>Número</label>
                                <input v-model="address.number" type="number" class="form-control form-control-sm" placeholder="Ex.: 1971"/>
                            </div>
                            <div class="col-sm-6 col-lg-4 col-xl-3 mb-1">
                                <label>Bairro</label>
                                <input v-model="address.district" type="text" class="form-control form-control-sm" placeholder="Ex.: Bairro Milton Friedman"/>
                            </div>
                            <div class="col-sm-6 col-lg-2 col-xl-2 mb-1">
                                <label>CEP</label>
                                <input v-model="address.postalCode" type="text" class="form-control form-control-sm" v-mask="'#####-###'"/>
                            </div>
                            <div class="col-12 mb-1">
                                <label>Complemento</label>
                                <input v-model="address.complement" type="text" class="form-control form-control-sm"/>
                            </div>
                            <div class="col-sm-6 col-lg-4 col-xl-4 mb-1">
                                <label>Cidade</label>
                                <input v-model="address.city" type="text" class="form-control form-control-sm"/>
                            </div>
                            <div class="col-sm-6 col-lg-4 col-xl-4 mb-1">
                                <label>Estado</label>
                                <input v-model="address.state" type="text" class="form-control form-control-sm"/>
                            </div>
                            <div class="col-sm-6 col-lg-4 col-xl-4 mb-1">
                                <label>País</label>
                                <input v-model="address.country" type="text" class="form-control form-control-sm"/>
                            </div>
                            <div style="width: 100%; text-align: center; margin-top: 0.5rem" @click="client.addresses.splice(client.addresses.indexOf(address), 1)">
                                <button type="button" class="btn btn-sm btn-danger">Excluir Endereço</button>
                            </div>
                        </div>
                    </div>

                    <div style="width: 100%; text-align: center; margin-top: 1rem">
                        <button class="btn btn-success" v-if="client.id === 0">Cadastrar Cliente</button>
                        <button class="btn btn-success" v-if="client.id !== 0">Atualizar Cliente</button>
                    </div>
                </form>

            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
import MenuBar from '../../template/MenuBar'
import SideMenu from '../../template/SideMenu'
import Footer from '../../template/Footer'
import axios from "axios";
import SelectPersonType from "@/components/pages/components/SelectPersonType";
import SelectPersonStatus from "@/components/pages/components/SelectPersonStatus";

export default {
    components: {
        SelectPersonStatus,
        SelectPersonType,
        MenuBar,
        SideMenu,
        Footer,
    },

    data: function () {
        return {
            client: {
                id: 0,
                name: '',
                active: true,
                personType: null,
                commercialPhone: null,
                privatePhone: null,
                addresses: [],
            },
        }
    },

    mounted() {
        if (axios.defaults.headers.common["Authorization"] == undefined || axios.defaults.headers.common["Authorization"] == '') {
            this.$router.push({name: 'Index'})
        }
    },

    created() {
        if (this.$route.query.id) {
            axios.get(this.$API_ADDRESS + '/client/' + this.$route.query.id)
                .then(response => {
                    this.client = response.data;
                })
        }
    },

    methods: {
        saveOrUpdate: function () {
            if (this.client.id !== 0) {
                axios.put(this.$API_ADDRESS + '/client', this.client)
                    .then(() => {
                        this.$router.push({name: 'ClientView', query: {'id': this.client.id}})
                    })
            } else {
                axios.post(this.$API_ADDRESS + '/client', this.client)
                    .then(response => {
                        this.$router.push({name: 'ClientView', query: {'id': response.data}})
                    })
            }
        },

    }
}


</script>

<style scoped>


</style>