<template>
    <select class="btn btn-sm btn-outline-secondary" aria-label="Unidade de Massa"
            :name="name" :value="value" v-on:input="$emit('input', $event.target.value)">>
        <option value="KILOGRAMS">kg</option>
        <option value="GRAMS">g</option>
        <option value="MILLIGRAMS">mg</option>
        <option value="MICROGRAMS">µg</option>
        <option value="STONES">st</option>
        <option value="POUNDS">lb</option>
        <option value="OUNCES">oz</option>
        <option value="GRAINS">gr</option>
    </select>
</template>

<script>
export default {
    name: "MassUnit",
    props: ['name', 'value']
}
</script>

<style scoped>

</style>