<template>
    <div id="sideMenuWrapper" @mouseleave="showName" @mouseover="removeName">

        <div id="fullWidthSideMenu">
            <div style="border-bottom: 1px solid azure;  margin-bottom: 0.5rem; text-align: center; margin: 0 auto; padding-top: 0.5rem;">
                <div style="height: 6rem">
                    <img v-if="loggedUser.license.image" :src="$API_ADDRESS + '/download/' + loggedUser.license.image" alt="Logo" class="companyLogo mb-2 mt-2" style="margin: 0 auto; "/>
                    <div class="sideMenuItem text-center company-name" id="companyItem" style="display: none;"><b>{{ loggedUser.license.companyName }}</b></div>
                </div>
                <div class="sideMenuItem" @click="$router.push('SystemAdministration')" v-if="loggedUser.license.id === 1"><i class="fas fa-lock-open" style="margin-right: 0.5rem"></i>Administração</div>
            </div>
            <div class="sideMenuItem" @click="$router.push('Products')"><i class="fas fa-barcode" style="margin-right: 0.5rem"></i>Produtos</div>
            <div class="sideMenuItem" @click="$router.push('ProductGroups')"><i class="fas fa-database" style="margin-right: 0.5rem"></i>Grupos de Produtos</div>
            <div class="sideMenuItem" @click="$router.push('Catalogs')"><i class="fas fa-book" style="margin-right: 0.5rem"></i>Catálogos</div>
            <div class="sideMenuItem" @click="$router.push('Clients')"><i class="fas fa-handshake" style="margin-right: 0.5rem"></i>Clientes</div>
            <div class="sideMenuItem" @click="$router.push('ClientOrders')"><i class="far fa-thumbs-up" style="margin-right: 0.5rem"></i>Pedidos</div>
            <div class="sideMenuItem" @click="$router.push('Inventory')" v-if="loggedUser.license.systemModules.indexOf('INVENTORY') >= 0">
                <i class="fas fa-boxes" style="margin-right: 0.5rem"></i>Estoque
            </div>
            <!--<div class="sideMenuItem" v-if="loggedUser.license.systemModules.indexOf('SERVICES') >= 0">
                <img src="../../assets/img/spanner_24_white.png" class="sideMenuItemImg"/>Serviços
            </div>
            <div class="sideMenuItem" v-if="loggedUser.license.systemModules.indexOf('ESTIMATE') >= 0">
                <img src="../../assets/img/budget_24_white.png" class="sideMenuItemImg"/>Orçamentos
            </div>
            <div class="sideMenuItem" v-if="loggedUser.license.systemModules.indexOf('PROVIDERS') >= 0">
                <img src="../../assets/img/budget_24_white.png" class="sideMenuItemImg"/>Fornecedores
            </div>

            <div class="sideMenuItem" v-if="loggedUser.license.systemModules.indexOf('FINANCIAL') >= 0">
                <img src="../../assets/img/money_24_white.png" class="sideMenuItemImg"/>Financeiro
            </div>
            <div class="sideMenuItem" v-if="loggedUser.license.systemModules.indexOf('REPORT') >= 0">
                <img src="../../assets/img/report_24_white.png" class="sideMenuItemImg"/>Relatórios
            </div>
            <div class="sideMenuItem" v-if="loggedUser.license.systemModules.indexOf('INVOICE') >= 0">
                <img src="../../assets/img/invoice_24_white.png" class="sideMenuItemImg"/>Notas Fiscais
            </div>
            <div class="sideMenuItem" v-if="loggedUser.license.systemModules.indexOf('SUBSIDIARIES') >= 0">
                <img src="../../assets/img/store_24_white.png" class="sideMenuItemImg"/>Filiais
            </div>
            <div class="sideMenuItem" v-if="loggedUser.license.systemModules.indexOf('CALENDAR') >= 0">
                <img src="../../assets/img/schedule_24_white.png" class="sideMenuItemImg"/>Agenda
            </div>
            <div class="sideMenuItem" v-if="loggedUser.license.systemModules.indexOf('CONTRACTS') >= 0">
                <img src="../../assets/img/contract_24_white.png" class="sideMenuItemImg"/>Contratos
            </div>
            <div class="sideMenuItem" v-if="loggedUser.license.systemModules.indexOf('EMPLOYEES') >= 0">
                <img src="../../assets/img/employees_24_white.png" class="sideMenuItemImg"/>Funcionários
            </div>-->
        </div>

        <div id="mobileSideMenu">
            <div style="border-bottom: 1px solid azure; margin-bottom: 0.5rem; text-align: center; margin: 0 auto; padding-top: 0.5rem;" @click="$router.push('SystemAdministration')">
                <img v-if="loggedUser.license.image" :src="$API_ADDRESS + '/download/' + loggedUser.license.image" alt="Logo" class="companyLogo" style="margin: 0 0 0.5rem 0"/>
                <div v-if="loggedUser.license.id === 1" class="sideMenuItem" data-bs-toggle="tooltip" data-bs-placement="right" title="Administração">
                    <i class="fas fa-lock-open fa-lg" data-bs-toggle="tooltip" data-bs-placement="right" title="Administração"></i>
                </div>
            </div>
            <div class="sideMenuItem" data-bs-toggle="tooltip" data-bs-placement="right" title="Produtos" @click="$router.push('Products')">
                <i class="fas fa-barcode fa-lg" data-bs-toggle="tooltip" data-bs-placement="right" title="Produtos"></i>

            </div>
            <div class="sideMenuItem" data-bs-toggle="tooltip" data-bs-placement="right" title="Grupos de Produtos" @click="$router.push('ProductGroups')">
                <i class="fas fa-database fa-lg" data-bs-toggle="tooltip" data-bs-placement="right" title="Grupos de Produtos"></i>
            </div>
            <div class="sideMenuItem" data-bs-toggle="tooltip" data-bs-placement="right" title="Catálogos" @click="$router.push('Catalogs')">
                <i class="fas fa-book fa-lg" data-bs-toggle="tooltip" data-bs-placement="right" title="Catálogos"></i>
            </div>
            <div class="sideMenuItem" data-bs-toggle="tooltip" data-bs-placement="right" title="Clientes" @click="$router.push('Clients')">
                <i class="fas fa-handshake fa-lg" data-bs-toggle="tooltip" data-bs-placement="right" title="Clientes"></i>
            </div>
            <div class="sideMenuItem" data-bs-toggle="tooltip" data-bs-placement="right" title="Pedidos" @click="$router.push('clientOrders')">
                <i class="far fa-thumbs-up fa-lg" data-bs-toggle="tooltip" data-bs-placement="right" title=""></i>
            </div>
            <div class="sideMenuItem" data-bs-toggle="tooltip" data-bs-placement="right" title="Estoque" @click="$router.push('Inventory')" v-if="loggedUser.license.systemModules.indexOf('INVENTORY') >= 0">
                <i class="fas fa-boxes fa-lg" data-bs-toggle="tooltip" data-bs-placement="right" title="Estoque"></i>
            </div>
            <!--
            <div class="sideMenuItem" data-bs-toggle="tooltip" data-bs-placement="right" title="Serviços" v-if="loggedUser.license.systemModules.indexOf('SERVICES') >= 0">
                <img src="../../assets/img/spanner_24_white.png" class="sideMenuItemImg" data-bs-toggle="tooltip" data-bs-placement="right" title="Serviços"/>
            </div>
            <div class="sideMenuItem" data-bs-toggle="tooltip" data-bs-placement="right" title="Orçamentos" v-if="loggedUser.license.systemModules.indexOf('ESTIMATE') >= 0">
                <img src="../../assets/img/budget_24_white.png" class="sideMenuItemImg" data-bs-toggle="tooltip" data-bs-placement="right" title="Orçamentos"/>
            </div>
            <div class="sideMenuItem" data-bs-toggle="tooltip" data-bs-placement="right" title="Fornecedores" v-if="loggedUser.license.systemModules.indexOf('PROVIDERS') >= 0">
                <img src="../../assets/img/budget_24_white.png" class="sideMenuItemImg" data-bs-toggle="tooltip" data-bs-placement="right" title="Fornecedores"/>
            </div>
            <div class="sideMenuItem" data-bs-toggle="tooltip" data-bs-placement="right" title="Estoque" @click="$router.push('Inventory')" v-if="loggedUser.license.systemModules.indexOf('INVENTORY') >= 0">
                <i class="fas fa-boxes fa-lg" data-bs-toggle="tooltip" data-bs-placement="right" title="Estoque"></i>
            </div>
            <div class="sideMenuItem" data-bs-toggle="tooltip" data-bs-placement="right" title="Financeiro" v-if="loggedUser.license.systemModules.indexOf('FINANCIAL') >= 0">
                <img src="../../assets/img/money_24_white.png" class="sideMenuItemImg" data-bs-toggle="tooltip" data-bs-placement="right" title="Financeiro"/>
            </div>
            <div class="sideMenuItem" data-bs-toggle="tooltip" data-bs-placement="right" title="Relatórios" v-if="loggedUser.license.systemModules.indexOf('REPORT') >= 0">
                <img src="../../assets/img/report_24_white.png" class="sideMenuItemImg" data-bs-toggle="tooltip" data-bs-placement="right" title="Relatórios"/>
            </div>
            <div class="sideMenuItem" data-bs-toggle="tooltip" data-bs-placement="right" title="Notas Fiscais" v-if="loggedUser.license.systemModules.indexOf('INVOICE') >= 0">
                <img src="../../assets/img/invoice_24_white.png" class="sideMenuItemImg" data-bs-toggle="tooltip" data-bs-placement="right" title="Notas Fiscais"/>
            </div>
            <div class="sideMenuItem" data-bs-toggle="tooltip" data-bs-placement="right" title="Filiais" v-if="loggedUser.license.systemModules.indexOf('SUBSIDIARIES') >= 0">
                <img src="../../assets/img/store_24_white.png" class="sideMenuItemImg" data-bs-toggle="tooltip" data-bs-placement="right" title="Filiais"/>
            </div>
            <div class="sideMenuItem" data-bs-toggle="tooltip" data-bs-placement="right" title="Agenda" v-if="loggedUser.license.systemModules.indexOf('CALENDAR') >= 0">
                <img src="../../assets/img/schedule_24_white.png" class="sideMenuItemImg" data-bs-toggle="tooltip" data-bs-placement="right" title="Agenda"/>
            </div>
            <div class="sideMenuItem" data-bs-toggle="tooltip" data-bs-placement="right" title="Contratos" v-if="loggedUser.license.systemModules.indexOf('CONTRACTS') >= 0">
                <img src="../../assets/img/contract_24_white.png" class="sideMenuItemImg" data-bs-toggle="tooltip" data-bs-placement="right" title="Contratos"/>
            </div>
            <div class="sideMenuItem" data-bs-toggle="tooltip" data-bs-placement="right" title="Funcionários" v-if="loggedUser.license.systemModules.indexOf('EMPLOYEES') >= 0">
                <img src="../../assets/img/employees_24_white.png" class="sideMenuItemImg" data-bs-toggle="tooltip" data-bs-placement="right" title="Funcionários"/>
            </div>
            -->
        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            loggedUser: JSON.parse(atob(sessionStorage.loggedUser))
        }
    },
    methods: {
        showName: function () {
            document.getElementById('companyItem').style.display = 'none'
        },
        removeName: function () {
            document.getElementById('companyItem').style.display = 'flex'
        }
    }
};
</script>

<style scoped>

#sideMenuWrapper {
    min-height: 100%;
    background-color: rgba(56, 66, 69, 0.95);
    overflow: auto;
    display: block;
}

.sideMenuItem {
    height: 2.5rem;
    width: 100%;
    color: white;
    display: flex;
    align-items: center;
    font-family: "sourcesanspro";
    padding-left: 1rem;
    font-size: 0.9rem;
    cursor: pointer;
    transition: 0.5s;
}

.sideMenuItem:hover {
    background-color: dimgray;
    color: #1ddcfc;
}

.sideMenuItemImg {
    margin-right: 0.5rem;
    width: auto;
    height: 20px;
}

@media screen and (max-width: 992px) {
    #sideMenuWrapper {
        width: 3.5rem;
        min-width: 3.5rem;
    }


    #fullWidthSideMenu {
        display: none;
    }

    #mobileSideMenu {
        display: block;
    }

    .companyLogo {
        margin-right: 0.5rem;
        width: auto;
        height: 26px;
        width: auto;
        margin-bottom: 0.5rem;

    }
}

@media screen and (min-width: 992px) {
    #sideMenuWrapper {
        width: 3.7rem;
        transition: 0.5s;
        overflow: hidden;
    }

    .company-name {
        min-width: 200px;
        align-items: center;
        padding-left: 0;
        font-size: 1.1rem;
        justify-content: center;
        margin-bottom: 1.5rem;
    }

    div i {
        min-width: 3.7rem;
        text-align: center;
        font-size: 1.2rem;
    }

    .sideMenuItem {
        white-space: nowrap;
    }

    .company-name {
        word-wrap: normal;
        white-space: normal;
    }

    #sideMenuWrapper:hover {
        width: 17rem;
        animation-name: expansive-content;
        animation-duration: 0.5s;
    }

    @keyframes expansive-content {
        from {
            width: 3.7rem;
        }
        to {
            width: 17rem;
        }
    }

    #fullWidthSideMenu {
        display: block;
    }

    .sideMenuItem {
        display: flex;
        padding-left: 0.1rem;
    }

    #mobileSideMenu {
        display: none;
    }

    .companyLogo {
        margin-right: 0.5rem;
        width: auto;
        height: 2.3rem;
        width: auto;
    }
}


</style>