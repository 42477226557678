<template>
    <div>
        <MenuBar/>
        <div class="contentOnNavBar">
            <SideMenu/>
            <div style="padding: 0.5rem; width: 100%">
                <div class="pageTitle" v-if="catalog.id === 0">Cadastrar Catálogo</div>
                <div class="pageTitle" v-if="catalog.id !== 0">Atualizar Catálogo</div>
                <div class="pageTitleDetails">Insira abaixo as informações do Catálogo. Campos marcados com "*" são obrigatórios.</div>

                <form class="row pt-3" style="margin-left: 0; margin-right: 0;" @submit.prevent="createUpdateCatalog">

                    <div class="col-sm-12 col-lg-9 col-xl-9 mb-1">
                        <label for="name">Nome *</label>
                        <input v-model="catalog.name" type="text" id="name" required class="form-control form-control-sm"/>
                    </div>

                    <div class="col-sm-12 col-lg-3 col-xl-3 mb-1">
                        <label for="validDate">Validade *</label>
                        <input v-model="catalog.validUntil" type="date" id="validDate" required class="form-control form-control-sm"/>
                    </div>

                    <div class="col-sm-12 col-lg-12 col-xl-12 mb-1">
                        <label for="introduction" class="customLabel">Introdução</label>
                        <textarea type="text" id="introduction" class="form-control form-control-sm" rows="5" v-model="catalog.introduction"/>
                    </div>

                    <div class="col-sm-6 col-lg-2 col-xl-1 mb-1 pt-2" style="margin: 0 auto">
                        <input v-model="catalog.active" class="form-check-input" type="checkbox" id="active" checked>&nbsp;
                        <label class="form-check-label" for="active"> Ativo </label>
                    </div>


                    <div class="pageSubTitle mb-3">Lista de Produtos</div>

                    <div>
                        <div class="col-sm-12 col-lg-12 col-xl-12 mb-12">
                            <AddProductToCatalog v-on:product-added="addProductToCatalog" :repeatedProduct="repeatedProduct"/>
                            <div class="mt-2 ms-3 text-danger" v-if="msgError">Ao menos um produto é obrigatório.</div>
                        </div>
                        <div id="products" style="margin-top: 1rem" v-if="catalog.products.length > 0">
                            <div class="row">
                                <div class="col-sm-12 col-lg-4 col-xl-4 mb-1">
                                    Nome do Produto
                                </div>
                                <div class="col-sm-12 col-lg-1 col-xl-1 mb-1">
                                    Preço
                                </div>
                                <div class="col-sm-12 col-lg-2 col-xl-2 mb-1" data-bs-toggle="tooltip" data-bs-placement="top"
                                     title="Quando marcado, o preço do produto no catálogo será definitivo.">
                                    Fixar Preço
                                </div>
                                <div class="col-sm-12 col-lg-1 col-xl-1 mb-1">
                                    Ações
                                </div>
                            </div>

                            <div class="row" v-for="product in catalog.products" :key="product.id">
                                <div class="col-sm-12 col-lg-4 col-xl-4 mb-1">
                                    <input v-model="product.name" disabled type="text" v-on:keyup="findProductByDescription" required class="form-control form-control-sm"/>
                                </div>
                                <div class="col-sm-12 col-lg-1 col-xl-1 mb-1">
                                    <input v-model="product.price" type="text" required class="form-control form-control-sm"/>
                                </div>
                                <div class="col-sm-12 col-lg-2 col-xl-2 mb-1">
                                    <input v-model="product.fixedPrice" class="form-check-input" type="checkbox"
                                           data-bs-toggle="tooltip" data-bs-placement="top"
                                           title="Quando marcado, o preço do produto no catálogo será definitivo."/>&nbsp;
                                </div>
                                <div class="col-sm-12 col-lg-1 col-xl-1 mb-1">
                                    <span class="badge" style="background-color: red" @click="removeProductFromCatalog(product.id)">
                                        <img src="../../../assets/img/trash_24_white.png" height="16" width="auto" class="cursorPointer"
                                             data-bs-toggle="tooltip" data-bs-placement="bottom" title="Excluir"/>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="pageSubTitle mb-3">Observações</div>

                    <div class="col-sm-12 col-lg-12 col-xl-12 mb-1">
                        <label for="observations" class="customLabel">Observações</label>
                        <textarea type="text" id="observations" class="form-control form-control-sm" rows="5" v-model="catalog.observations"/>
                    </div>

                    <div style="width: 100%; text-align: center">
                        <button class="btn btn-success mt-2" v-if="catalog.id === 0">Cadastrar Catálogo</button>
                        <button class="btn btn-success mt-2" v-if="catalog.id !== 0">Atualizar Catálogo</button>
                    </div>
                </form>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
import MenuBar from '../../template/MenuBar'
import SideMenu from '../../template/SideMenu'
import Footer from '../../template/Footer'
import axios from "axios";
import AddProductToCatalog from "@/components/pages/components/AddProductToCatalog";

export default {
    components: {
        AddProductToCatalog,
        MenuBar,
        SideMenu,
        Footer,
    },
    data: function () {
        return {
            catalog: {
                id: 0,
                name: '',
                introduction: '',
                observations: '',
                active: true,
                validUntil: null,
                products: []
            },
            repeatedProduct: false,
            msgError: false
        }
    },

    mounted() {
        if (axios.defaults.headers.common["Authorization"] == undefined || axios.defaults.headers.common["Authorization"] == '') {
            this.$router.push({name: 'Index'})
        }

        if (this.$route.query.id !== undefined) {
            this.getCatalog();
        } else if (this.$route.query.productIds !== undefined) {
            axios.get(this.$API_ADDRESS + '/product/getByIdList?idList=' + this.$route.query.productIds)
                .then(response => {
                    response.data.forEach(product => {
                        if (this.catalog.products.indexOf(product) < 0) {
                            this.catalog.products.push(product)
                        }
                    })
                })
                .catch(e => {
                    this.showMessage(e, 'ERROR');
                })
        }
    },

    updated() {
        if (this.catalog.products.length !== 0) {
            this.msgError = false
        }
    },

    methods: {
        createUpdateCatalog: function () {
            if (this.catalog.products.length !== 0) {
                if(this.catalog.id === 0){
                axios.post(this.$API_ADDRESS + '/catalog', this.catalog)
                    .then(response => {
                        this.$router.push({name: 'CatalogViewInternal', query: {'id': response.data}})
                    })
                } else if(this.catalog.id !== 0){
                    axios.put(this.$API_ADDRESS + '/catalog', this.catalog)
                    .then(() => {
                        this.$router.push({name: 'CatalogViewInternal', query: {'id': this.catalog.id}})
                    })
                }
            } else {
                this.msgError = true
            }
        },

        addProductToCatalog(productItem) {
            let productAdd = true
            this.repeatedProduct = false
            this.catalog.products.forEach(p => {
                if (p.id === productItem.id) {
                    this.repeatedProduct = true
                    productAdd = false
                }
            })

            if (productAdd) {
                productItem.fixedPrice = false;
                Object.assign(productItem, {product: {id: productItem.id}})
                this.catalog.products.push(productItem)
            }
        },

        removeProductFromCatalog: function (productId) {
            this.catalog.products.forEach(p => {
                if (p.id === productId) {
                    this.catalog.products.splice(this.catalog.products.indexOf(p), 1)
                }
            })
        },

        findProductByDescription: function () {
            if (this.description.length == 3) {
                axios.get(this.$API_ADDRESS + '/product/getByDescription?description=' + this.description, null)
                    .then(response => {
                        this.foundProducts = response.data;
                    })
            }
        },

        getCatalog() {
            axios.get(this.$API_ADDRESS + '/catalog/' + this.$route.query.id)
                .then(response => {
                    this.catalog = response.data
                })
        },
    }
}


</script>

<style scoped>


</style>