<template>
    <div style="font-family: sourcesanspro">
        <BigHeaderCatalogView ref="header"/>

        <div class="contentOnBigHeader" style="background-color: #eeeeee;" v-if="catalog">
            <h4 style="text-align: center; margin-top: 0.5rem">{{ catalog.name }}</h4>
            <div class="row" style="margin: 0.5rem">
                <div class="col-g-12 col-md-4 col-lg-3">
                    <div style="margin-bottom: 1rem; text-align: center">
                        <h5>Compartilhar Catálogo</h5>
                        <a :href="'https://twitter.com/share?url=' + this.getEncodedUrl()">
                            <img src="../../../assets/img/twitter.png" class="shareBtn">
                        </a>
                        <a :href="'https://facebook.com/sharer/sharer.php?u=' + this.getEncodedUrl()">
                            <img src="../../../assets/img/facebook.png" class="shareBtn">
                        </a>
                        <a :href="'whatsapp://send?text=' + this.getEncodedUrl()">
                            <img src="../../../assets/img/whatsapp.png" class="shareBtn">
                        </a>
                        <a :href="'https://www.linkedin.com/shareArticle?mini=true&url=' + this.getEncodedUrl()">
                            <img src="../../../assets/img/linkedin.png" class="shareBtn">
                        </a>
                        <a :href="this.getEmailShare()">
                            <img src="../../../assets/img/email.png" class="shareBtn">
                        </a>
                    </div>

                    <div class="mb-2">
                        <h5>Filtrar Produtos: </h5>
                        <input type="text" class="form-control" id="filterInput" v-model="filter">
                    </div>
                    <h5>Categorias: </h5>
                    <div class="row" v-for="pg in productGroups" :key="pg.name">
                        <div class="col-12">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" v-on:click="switchFilterGroup(pg.id)">
                                <span style="color: #007d71">{{ pg.name }}</span> (<b>{{ pg.count }}</b>)
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-g-12 col-md-8 col-lg-9">
                    <p style="text-align: justify">{{ catalog.introduction }}</p>
                    <div class="row" style="text-align: center; margin: 0 auto;">
                        <div class="col-g-12 col-md-6 col-lg-3 card" style=""
                             v-for="(catalogProduct, index) in catalogProducts" :key="index">
                            <div class="card-body" style="text-align: center; font-size: 0.9rem;">
                                <span @click="$router.push({name: 'CatalogProductView',query:{productId: catalogProduct.product.id, catalogId: catalog.id}})" style="cursor: pointer">{{ catalogProduct.product.name }}</span><br/>
                                <img :src="$API_ADDRESS + '/download/' + catalogProduct.product.images[0]" style="height: 6rem" v-if="catalogProduct.product.images.length > 0">
                                <img src="../../../assets/img/product.png" style="height: 6rem" v-if="catalogProduct.product.images.length == 0">
                                <br/>R$: {{ catalogProduct.sellPrice }}<br/>

                                <button class="btn btn-primary btn-sm" @click="addProductToOrder(catalogProduct)" v-if="!$refs.header.orderHasProduct(catalogProduct.id)">Adicionar Ao Pedido</button>
                                <button class="btn btn-danger btn-sm" @click="removeProductFromOrder(catalogProduct)" v-if="$refs.header.orderHasProduct(catalogProduct.id)">Remover do Pedido</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Footer/>
    </div>
</template>

<script>
import Footer from '../../template/Footer'
import BigHeaderCatalogView from "@/components/template/BigHeaderCatalogView";

export default {
    components: {
        BigHeaderCatalogView,
        Footer,
    },

    async mounted() {
        this.catalog = await this.$refs.header.getCatalog(this.$route.query.id)
        this.clientOrder = this.$refs.header.getClientOrder()
        this.organizeProductGroups()
    },

    data: function () {
        return {
            catalog: null,
            clientOrder: null,
            filter: '',
            productGroups: [],
            filterGroups: [],
            catalogProducts: [],
        }
    },

    watch: {
        'filter': function (val) {
            this.filter = val;
            this.filterProducts();
        }
    },

    methods: {
        addProductToOrder: function (product) {
            this.clientOrder = this.$refs.header.addProductToOrder(product)
        },

        removeProductFromOrder: function (product) {
            this.clientOrder = this.$refs.header.removeProductFromOrder(product)
        },

        organizeProductGroups: function () {
            this.catalog.catalogProducts.forEach(catalogProduct => {
                if (catalogProduct.product.productGroups !== undefined) {
                    catalogProduct.product.productGroups.forEach(productGroup => {
                        if (this.productGroups.some(c => c.name === productGroup.name)) {
                            this.productGroups.find(pg => {
                                return pg.name === productGroup.name
                            }).count++;
                        } else {
                            this.productGroups.push({name: productGroup.name, count: 1, id: productGroup.id})
                        }
                    })
                }
            })
            this.productGroups.sort((a, b) => {
                return b.count - a.count;
            });
            this.filterProducts()
        },

        switchFilterGroup: function (filterGroup) {
            if (this.filterGroups.indexOf(filterGroup) < 0) {
                this.filterGroups.push(filterGroup)
            } else {
                this.filterGroups.splice(this.filterGroups.indexOf(filterGroup), 1)
            }
            this.filterProducts();
        },

        filterProducts: function () {
            this.products = []
            if (this.filterGroups.length > 0) {
                this.catalog.catalogProducts.forEach(cp => {
                    if (cp.product.productGroups.length > 0) {
                        this.filterGroups.forEach(filterId => {
                            cp.product.productGroups.forEach(pg => {
                                if (pg.id === filterId && cp.product.name.toLowerCase().indexOf(this.filter.toLowerCase()) !== -1) {
                                    this.products.push(cp.product)
                                }
                            })
                        })
                    }
                })
            } else {
                this.catalog.catalogProducts.forEach(catalogProduct => {
                    if (catalogProduct.name.toLowerCase().indexOf(this.filter.toLowerCase()) !== -1) {
                        this.catalogProducts.push(catalogProduct)
                    }
                })
            }
        },

        getEncodedUrl: function () {
            return encodeURIComponent('https://www.newspirit.com.br/catalogView?id=' + this.catalog.id)
        },

        getEmailShare: function () {
            return 'mailto:?subject=' + encodeURIComponent(this.catalog.name);
        }
    }
}
</script>

<style scoped>
#headerWrapper {
    height: 5rem;
    justify-content: center;
}

h3 {
    margin-left: 0.4rem;
    font-size: 2rem;
    font-family: 'righteous';
}

.shareBtn {
    margin-left: 0.4rem;
    height: 1.7rem;
    width: 1.7rem;
}
</style>