<template>
    <div>
        <MenuBar/>
        <div class="contentOnNavBar">
            <SideMenu/>
            <div style="padding: 0.5rem; width: 100%">
                <div class="pageTitle">Grupos de Produtos</div>

                <div style="padding: 1rem 0 0.5rem 0; width: 100%;">
                    <NewProductGroup v-on:group-created="addCreatedGroupToGroups"/>
                    <button class="btn btn-danger btn-sm" style="float: right;" data-bs-toggle="modal" data-bs-target="#confirmDelete"
                            :disabled="selectedProductGroups.length === 0">Excluir Selecionados
                    </button>
                </div>

                <div id="no-more-tables">
                    <table class="table table-sm" style="padding-top: 0.5rem;" v-if="productGroups">
                        <thead>
                        <tr>
                            <th scope="col">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" @click="selectAllOrNone">
                                </div>
                            </th>
                            <th scope="col">Nome
                                <i class="fas fa-sort" v-if="!$refs.pagination.sortFieldsContainsField('name', null)" @click="$refs.pagination.addSortField('name')"></i>
                                <i class="fas fa-sort-up" v-if="$refs.pagination.sortFieldsContainsField('name', 'ASC')" @click="$refs.pagination.addSortField('name')"></i>
                                <i class="fas fa-sort-down" v-if="$refs.pagination.sortFieldsContainsField('name', 'DESC')" @click="$refs.pagination.addSortField('name')"></i>
                            </th>
                            <th scope="col">Cor</th>
                            <th scope="col">Produtos</th>
                            <th scope="col">Ações</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(group, index) in productGroups" :key="index">
                            <td data-title="Selecionar" class="align-middle">
                                <div class="form-check">
                                    <input class="form-check-input" :value="group.id" type="checkbox" v-model="selectedProductGroups">
                                </div>
                            </td>
                            <td data-title="Nome">{{ group.name }}</td>
                            <td data-title="Cor">{{ group.color }}</td>
                            <td data-title="Produtos">{{ group.cont }}</td>
                            <td>
                                <div class="btn-group " role="group">
                                    <button id="btnGroupDrop1" type="button" class="btn btn-warning btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                        Ações
                                    </button>
                                    <ul class="dropdown-menu" aria-labelledby="btnGroupDrop1" style="min-width: unset !important; padding: 0 !important">
                                        <li><a class="dropdown-item sm" @click="selectedProductGroups = []; selectedProductGroups.push(group.id)" data-bs-toggle="modal" data-bs-target="#confirmDelete" style="cursor: pointer">
                                            <i class="fas fa-trash fa-sm" style="margin-right: 0.5rem"></i>Excluir</a></li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <Pagination ref="pagination" v-on:page-clicked="(p) => this.productGroups = p" endpoint-list="/productGroup/list/" endpoint-count="/productGroup/count"/>
                <ConfirmDelete v-on:done="$refs.pagination.getObjects(-1)" :objects-to-exclude="selectedProductGroups"
                               :message="'Tem certeza que deseja excluir o grupo de produtos selecionado?'" :endpoint="'/productGroup'"/>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
import MenuBar from '../../template/MenuBar'
import SideMenu from '../../template/SideMenu'
import Footer from '../../template/Footer'
import NewProductGroup from "../components/NewProductGroup"
import Pagination from "@/components/pages/components/Pagination";
import ConfirmDelete from "@/components/pages/components/ConfirmDelete";
import axios from "axios";

export default {
    components: {
        ConfirmDelete,
        MenuBar,
        SideMenu,
        Footer,
        NewProductGroup,
        Pagination
    },

    data: function () {
        return {
            productGroups: null,
            selectedProductGroups: []
        }
    },

    mounted() {
        if (axios.defaults.headers.common["Authorization"] == undefined || axios.defaults.headers.common["Authorization"] == '') {
            this.$router.push({name: 'Index'})
        }
    },

    methods: {
        addCreatedGroupToGroups: function (group) {
            this.productGroups.push(group);
        },

        selectAllOrNone() {
            if (this.selectedProductGroups.length > 0) {
                this.selectedProductGroups = []
            } else {
                this.selectedProductGroups = []
                this.productGroups.forEach(p => {
                    this.selectedProductGroups.push(p.id)
                })
            }
        },
    }
}
</script>

<style scoped>
</style>