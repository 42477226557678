<template>
    <div>
        <BigHeader/>

        <div class="vCenterWrapper contentOnBigHeader">
            <form id="emailConfirmForm" style="width: 20rem; font-family: roboto; margin: 0 auto;" @submit.prevent="doEmailConfirmation">

                <div class="alert alert-danger" role="alert" id="confirmationAlert" style="display: none;"></div>

                <div style="text-align: center; width: 100%; margin-bottom: 2rem;">
                    <div style="font-weight: bold; font-size: 1.6rem;">Confirmar Email</div>
                </div>
                <div class="mb-3">
                    <label for="confirmationEmail" class="form-label">Informe seu email:</label>
                    <input type="email" class="form-control" id="confirmationEmail" required v-model="confirmation.email">
                </div>
                <div class="mb-3">
                    <label for="confirmationCode" class="form-label">Código enviado por email:</label>
                    <input type="text" class="form-control" id="confirmationCode" required v-model="confirmation.code">
                </div>
                <div style="width: 100%; text-align: center; line-height: normal;">
                    <button type="submit" class="btn btn-primary" style="margin: 0 auto;">Enviar</button>
                </div>
            </form>
        </div>

        <Footer/>
    </div>


</template>

<script>
import BigHeader from '../../template/BigHeader'
import Footer from '../../template/Footer'
import axios from "axios";

export default {
    components: {
        BigHeader,
        Footer
    },

    data: function () {
        return {
            confirmation: {
                code: '',
                email: ''
            }
        }
    },

    mounted() {
        if (this.$route.query.email !== '') {
            this.confirmation.email = this.$route.query.email;
        }
    },

    methods: {
        doEmailConfirmation: function () {
            axios.post(this.$API_ADDRESS + '/license/validate', this.confirmation)
                .then(response => {
                    axios.defaults.headers.common = {
                        'Authorization': response.data.authorization,
                    };
                    sessionStorage.setItem('Authorization', response.data.authorization)
                    delete response.data.Authorization
                    sessionStorage.setItem('loggedUser', btoa(JSON.stringify(response.data.loggedUser)))
                    this.$router.push({name: 'Home'});
                })
        }
    }
}
</script>

<style scoped>
.form-label {
    margin-bottom: 0;
}
</style>