<template>
    <select class="btn btn-sm btn-outline-secondary" aria-label="Unidade de Comprimento"
            v-bind:name="name" v-bind:value="value" v-on:input="$emit('input', $event.target.value)">>
        <option value="METERS">m</option>
        <option value="CENTIMETERS">cm</option>
        <option value="MILLIMETERS">mm</option>
        <option value="YARDS">yd</option>
        <option value="FEET">ft</option>
        <option value="INCHES">in</option>
    </select>
</template>

<script>
export default {
    name: "LengthUnit",
    props: ['name', 'value']
}
</script>

<style scoped>

</style>