<template>
    <div class="vCenterWrapper bg-primary " id="headerWrapper">
        <img @click="$router.push({name: 'Index'})"
             id="logoImg" style="cursor: pointer"
             src="../../assets/img/logo.png"
             alt="New Spirit Logo"
             height="40rem"
             width="auto"
        />

        <h3 @click="$router.push({name: 'Index'})" style="cursor: pointer" class="fs-2">New Spirit Catálogo</h3>
    </div>
</template>

<script>
export default {};
</script>

<style scoped>
#headerWrapper {
    height: 5rem;
    justify-content: center;
}

h3 {
    margin-left: 0.4rem;
    font-size: 2rem;
    font-family: 'righteous';
}
</style>