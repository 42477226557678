<template>
    <div>
        <BigHeader/>

        <div class="vCenterWrapper contentOnBigHeader">
            <h1 style="margin: 0 auto; text-align: center">404 - Não Encontrado</h1>
        </div>

        <Footer/>
    </div>
</template>

<script>

import BigHeader from "@/components/template/BigHeader";
import Footer from "@/components/template/Footer";

export default {
    components: {
        Footer,
        BigHeader,
    }
}
</script>

<style scoped>

</style>