import { render, staticRenderFns } from "./ClientOrderCreate.vue?vue&type=template&id=5ce65010&scoped=true&"
import script from "./ClientOrderCreate.vue?vue&type=script&lang=js&"
export * from "./ClientOrderCreate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ce65010",
  null
  
)

export default component.exports