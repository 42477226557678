<template>
    <div style="display: inline-block">
        <!-- Button trigger modal -->
        <button type="button" class="btn btn-primary btn-sm ms-3" data-bs-toggle="modal" data-bs-target="#newProductGroup">
            Adicionar Produto
        </button>

        <!-- Modal -->
        <div class="modal fade" id="newProductGroup" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" dy>
            <div class="modal-dialog modal-dialog-centered">
                <form class="modal-content" @submit.prevent="">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Adicionar Produto ao Catálogo</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <label for="searchProd" class="form-label">Nome, descrição ou código do produto:</label>
                        <span class="text-danger d-flex" v-if="repeatedProduct">Produto já adicionado!</span>
                        <div class="input-group mb-2">
                            <input v-model="description" placeholder="Nome, descrição ou código interno..." type="text" id="searchProd" v-on:keyup.enter="findProductByDescription"
                                   required class="form-control form-control-sm">
                            <div class="btn-sm btn-secondary" type="button" @click="findProductByDescription">
                                <i class="fas fa-search"/>
                            </div>
                        </div>
                        <div class="mb-2" style="max-height: 380px; overflow-y: scroll">
                            <div class="row" v-for="product in foundProducts" :key="product.id" style="display: inline;">
                                <div class="col-12">
                                    <button type="submit" class="btn btn-sm btn-primary" style="width: auto" v-on:click="$emit('product-added', product)">+</button>
                                    {{ product.name }} (<b style="padding: 0;">{{ product.internalCode }}</b>)
                                    <br/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id="cancelBtn">Fechar</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    props: ['repeatedProduct'],

    data: function () {
        return {
            description: '',
            foundProducts: [],
        }
    },

    methods: {
        findProductByDescription: function () {
            if (this.description.length >= 3) {
                axios.get(this.$API_ADDRESS + '/product/getByDescription?description=' + this.description, null)
                    .then(response => {
                        this.foundProducts = response.data;
                    })
            }
        },
    }
}
</script>

<style scoped>

</style>