<template>
    <div style="display: inline-block">
        <!-- Modal -->
        <div class="modal fade" id="shareChooseDialog" tabindex="-1" aria-labelledby="exampleModalLabel4873"
             aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <form class="modal-content" @submit.prevent="">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel4873">Compartilhar</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Fechar"></button>
                    </div>
                    <div class="modal-body" v-if="urlToShare">
                        <div style="margin-bottom: 1rem; text-align: center">
                            <h5>Selecione o destino:</h5>
                            <img v-if="false" src="../../../assets/img/copy.png" style="cursor: pointer" class="shareBtn" v-clipboard:copy="urlToShare"/>
                            <a :href="'https://twitter.com/share?url=' + getEncodedUrl()">
                                <img src="../../../assets/img/twitter.png" class="shareBtn">
                            </a>
                            <a :href="'https://facebook.com/sharer/sharer.php?u=' + getEncodedUrl()">
                                <img src="../../../assets/img/facebook.png" class="shareBtn">
                            </a>
                            <a :href="'https://web.whatsapp.com/send?text=' + getEncodedUrl()"
                               data-action="share/whatsapp/share">
                                <img src="../../../assets/img/whatsapp.png" class="shareBtn">
                            </a>
                            <a :href="'https://www.linkedin.com/shareArticle?mini=true&url=' + getEncodedUrl()">
                                <img src="../../../assets/img/linkedin.png" class="shareBtn">
                            </a>
                            <a :href="this.getEmailShare()">
                                <img src="../../../assets/img/email.png" class="shareBtn">
                            </a>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div style="width: 100%">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                                    style="float: right">Fechar
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>

export default {

    data: function () {
        return {
            urlToShare: null
        }
    },

    methods: {
        getEmailShare() {
            return '';
        },

        getEncodedUrl() {
            return encodeURI(this.urlToShare);
        },

    }

}
</script>

<style scoped>
.shareBtn {
    height: 4.5rem;
    width: 4.5rem;
    padding: 0.7rem;
    border-radius: 0.4rem;
}

.shareBtn:hover {
    background-color: #999;
}
</style>