<template>
    <select class="btn btn-sm " aria-label="Classificação" style="background-color: white; width: 100%; border: 1px solid #ced4da"
            :name="name" :value="value" v-on:input="$emit('input', $event.target.value)">>
        <option value="RECEIVED">Recebido</option>
        <option value="SEEN">Visto</option>
        <option value="PROCESSED">Processado</option>
        <option value="DELIVERED">Entregue</option>
        <option value="FINISHED">Finalizado</option>
    </select>
</template>

<script>
export default {
    props: ['name', 'value']
}
</script>

<style scoped>

</style>