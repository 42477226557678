<template>
    <div>
        <MenuBar/>
        <div class="contentOnNavBar">
            <SideMenu/>
            <div style="padding: 0.5rem; width: 100%">
                <div class="pageTitle">Visualizar Produto</div>
                <div class="pageTitleDetails">Abaixo as informações do Produto.</div>

                <div v-if="product" style="padding: 0.5rem;">


                    <div class="pageSubTitle mb-2">Informações Básicas</div>
                    <span class="label">Nome: </span>{{ product.name }}<br/>
                    <span class="label">Código Interno: </span>{{ product.internalCode }}<br/>
                    <span class="label">Código de Barras: </span>{{ product.barCode }}<br/>
                    <div style="text-overflow: ellipsis;overflow: hidden; word-wrap: break-word" id="descriptionProduct">
                        <span class="label">Descrição: </span>{{ product.description }}<br/>
                    </div>
                    <span class=" label">Ativo: </span>{{ product.active ? 'Sim' : 'Não' }}


                    <div class="pageSubTitle mb-2">Grupos de Produtos</div>
                    <span class="label">Grupos: </span>
                    <div v-for="group in product.productGroups" :key="group.id" style="display: inline">
                        <span class="badge m-1" :style="getProductGroupColor(group)">{{ group.name }}</span>
                    </div>


                    <div v-if="product.details">
                        <div class="pageSubTitle mb-2">Detalhes do Produto</div>
                        <span class="label">NCM: </span>{{ product.details.ncm }}<br/>
                        <span class="label">CEST: </span>{{ product.details.cest }}<br/>
                        <span class="label">Peso: </span>{{ product.details.weight }} {{ product.details.weightUnit }}<br/>
                        <span class="label">Largura: </span>{{ product.details.width }} {{ product.details.widthUnit }}<br/>
                        <span class="label">Altura: </span>{{ product.details.height }} {{ product.details.heightUnit }}<br/>
                        <span class="label">Comprimento: </span>{{ product.details.length }} {{ product.details.lengthUnit }}<br/>
                        <span class="label">Comissão: </span>{{ product.details.comission }}<br/>
                    </div>


                    <div class="pageSubTitle mb-2">Imagens do Produto</div>
                    <div style="margin: 0 auto; text-align: center">
                        <ImageCarousel :images-list="product.images"/>
                    </div>

                    <div class="pageSubTitle mb-2">Valores e Custos</div>
                    <span class="label">Preço de Custo: </span>{{ product.costPriceUnit }} {{ product.costPrice }}<br/>
                    <span class="label">Outros Custos: </span>{{ product.otherCostsUnit }} {{ product.otherCosts }}<br/>
                    <span class="label">Custo Final: </span>{{ product.finalCostUnit }} {{ product.finalCost }}<br/>
                    <span class="label">Margem: </span>{{ product.margin }} <br/>
                    <span class="label">Preço de Venda: </span>{{ product.sellPriceUnit }} {{ product.sellPrice }}<br/>


                    <div class="pageSubTitle mb-2">Variações</div>
                    <span v-for="(val, valIndex) in product.productVariations" :key="valIndex" class="badge rounded-pill bg-primary pl-3" style="margin-left: 0.3rem;">
                        {{ val.name }}: {{ val.possibleValues }}
                    </span>


                    <div class="pageSubTitle mb-2">Observações</div>
                    <span class="label">Observações: </span> {{ product.observations }}<br/>
                    <span class="label">Observações Internas: </span> {{ product.internalObservations }}
                </div>

                <div style="margin: 0 auto; width: 100%; text-align: center">
                    <div class="btn-group " role="group">
                        <button id="btnGroupDrop1" type="button" class="btn btn-primary btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                            Ações
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="btnGroupDrop1" style="min-width: unset !important; padding: 0 !important">
                            <li><a class="dropdown-item sm" style="cursor: pointer" @click="$router.push({name: 'ProductCreate', query:{id: product.id}})"><i class="fas fa-pen" style="margin-right: 0.5rem"></i>Editar</a></li>
                            <li><a class="dropdown-item sm" style="cursor: pointer"> <i class="fas fa-trash" style="margin-right: 0.5rem"></i>Excluir</a></li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
import MenuBar from '../../template/MenuBar'
import SideMenu from '../../template/SideMenu'
import Footer from '../../template/Footer'
import axios from "axios";
import ImageCarousel from "@/components/pages/components/ImageCarousel";

export default {
    components: {
        ImageCarousel,
        MenuBar,
        SideMenu,
        Footer,
    },

    mounted() {
        if (axios.defaults.headers.common["Authorization"] == undefined || axios.defaults.headers.common["Authorization"] == '') {
            this.$router.push({name: 'Index'})
        }
    },

    created() {
        if (this.$route.query.id !== '') {
            axios.get(this.$API_ADDRESS + '/product/' + this.$route.query.id)
                .then(response => {
                    this.product = response.data;
                })
        }
    },

    data: function () {
        return {
            product: null
        }
    },

    methods: {
        getProductGroupColor(product) {
            return 'cursor: pointer; background-color: ' + product.color;
        },
    }
}
</script>

<style scoped>
@media (min-width: 700px) {
    #descriptionProduct {
        width: 100%;
    }
}

@media (max-width: 700px) {
    #descriptionProduct {
        width: 80%;
    }
}
</style>