<template>
    <div style="font-family: sourcesanspro">
        <BigHeaderCatalogView ref="header"/>

        <div class="contentOnBigHeader" style="background-color: #eeeeee;" v-if="clientOrder && catalog">
            <form @submit.prevent="sendClientOrder">
                <h4 style="text-align: center; margin-top: 1rem">Finalizar Pedido</h4>
                <div class="row" style="margin: 0.5rem">
                    <div class="col-sm-1 col-md-4">
                        <label for="name" class="customLabel">Nome *</label>
                        <input v-model="clientOrder.client.name" type="text" minlength="3" required id="name" class="form-control form-control-sm"/>
                    </div>

                    <div class="col-sm-1 col-md-4">
                        <label for="email" class="customLabel">Email *</label>
                        <input v-model="clientOrder.client.email" type="email" required id="email" class="form-control form-control-sm"/>
                    </div>

                    <div class="col-sm-1 col-md-4">
                        <label for="celPhone" class="customLabel">Telefone Celular *</label>
                        <input v-model="clientOrder.client.privatePhone" type="text" required id="celPhone" class="form-control form-control-sm"
                               v-mask="'(##) #####-####'"/>
                    </div>

                    <div class="col-12">
                        <label for="observations" class="customLabel">Observações</label>
                        <textarea v-model="clientOrder.observations" type="text" rows="5" id="observations" class="form-control form-control-sm"/>
                    </div>
                </div>
                <h5 style="text-align: center; margin-top: 1rem">Sua Lista de Produtos</h5>
                <div class="row" style="margin: 0.5rem">
                    <div style="width: 100%; text-align: right">
                        <button class="btn btn-sm btn-primary" @click="$router.push({name: 'CatalogView',query:{id: catalog.id}})">Adicionar Produtos</button>
                    </div>

                    <div style="width: 100%; padding-top: 0.5rem;" v-if="clientOrder">
                        <table class="table table-sm">
                            <thead>
                            <tr>
                                <th scope="col">Nome do Produto</th>
                                <th scope="col">Quantidade</th>
                                <th scope="col">Valor Unitário</th>
                                <th scope="col">Subtotal</th>
                                <th scope="col">Ações</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(catalogProduct, index) in clientOrder.products" :key="index">
                                <td>{{ catalogProduct.name }}</td>
                                <td>
                                    <input type="number" id="quantity" style="width: 5rem" v-model="catalogProduct.quantity" v-on:input="getClientOrderTotal()">
                                </td>
                                <td>R$ {{ catalogProduct.sellPrice.toLocaleString() }}</td>
                                <td>R$ {{ (catalogProduct.sellPrice * catalogProduct.quantity).toLocaleString() }}</td>
                                <td>
                                    <button class="btn btn-danger btn-sm" style="margin-left: 0.3rem; padding: 0.1rem" @click="removeProductFromOrder(catalogProduct)"
                                            data-bs-toggle="tooltip" data-bs-placement="bottom" title="Excluir">
                                    <span class="badge ">
                                        <img src="../../../assets/img/trash_24_white.png" height="16" width="auto" class="cursorPointer"
                                             data-bs-toggle="tooltip" data-bs-placement="bottom" title="Excluir"/>
                                    </span>
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <h5 v-if="clientOrder.total" style="text-align: center; margin-top: 0.4rem">Total: R$ {{ clientOrder.total.toLocaleString() }}</h5>
                        <div style="width: 100%; text-align: center">
                            <input type="submit" class="btn btn-success" value="Finalizar Pedido"/>
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <Footer/>
    </div>
</template>

<script>
import Footer from '../../template/Footer'
import BigHeaderCatalogView from "@/components/template/BigHeaderCatalogView";
import axios from "axios";

export default {
    components: {
        BigHeaderCatalogView,
        Footer
    },

    async mounted() {
        this.catalog = await this.$refs.header.getCatalog(null)
        this.clientOrder = this.$refs.header.getClientOrder()
        this.getClientOrderTotal()
    },

    data: function () {
        return {
            catalog: null,
            clientOrder: null,
        }
    },

    methods: {
        removeProductFromOrder: function (product) {
            this.clientOrder = this.$refs.header.removeProductFromOrder(product)
            this.getClientOrderTotal()
        },

        saveOrderToSessionStorage: function () {
            sessionStorage.setItem('clientOrder', btoa(JSON.stringify(this.clientOrder)))
        },

        getClientOrderTotal: function () {
            let total = 0.0;
            this.clientOrder.products.forEach(p => {
                total += p.sellPrice * p.quantity
            })
            this.clientOrder.total = total
            this.saveOrderToSessionStorage()
        },

        sendClientOrder: function () {
            if (this.clientOrder.products.length === 0) {
                this.showMessage("Nenhum Produto Adicionado!", 'ERROR');
                return;
            }
            this.clientOrder.products.forEach(catalogProduct => {
                delete catalogProduct.description
                delete catalogProduct.name
                catalogProduct.value = catalogProduct.price
                catalogProduct.license = this.clientOrder.company
            })
            this.clientOrder.license = this.clientOrder.company
            this.clientOrder.client.license = this.clientOrder.company

            axios.post(this.$API_ADDRESS + '/clientOrder', this.clientOrder)
                .then(() => {
                    this.clientOrder = this.$refs.header.createNewOrderOnSessionStorage()
                    //this.$router.push({name: 'CatalogView',query:{id: this.catalog.id}});
                    this.showMessage("Pedido Realizado!", 'INFO');
                })
        },
    }
}
</script>

<style scoped>
#headerWrapper {
    height: 5rem;
    justify-content: center;
}

h3 {
    margin-left: 0.4rem;
    font-size: 2rem;
    font-family: 'righteous';
}
</style>