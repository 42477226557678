import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router';
import VueMask from 'v-mask'

import Catalogs from "@/components/pages/private/Catalogs";
import CatalogView from "@/components/pages/public/CatalogView";
import CatalogViewInternal from "@/components/pages/private/CatalogViewInternal";
import CatalogCreate from "@/components/pages/private/CatalogCreate";
import CatalogProductView from "@/components/pages/public/CatalogProductView";
import CheckoutOrder from "@/components/pages/public/CheckoutOrder";
import ClientOrders from "@/components/pages/private/ClientOrders";
import ClientOrderView from "@/components/pages/private/ClientOrderView";
import ClientOrderCreate from "@/components/pages/private/ClientOrderCreate";
import Clients from "@/components/pages/private/Clients";
import ClientCreate from "@/components/pages/private/ClientCreate";
import ClientView from "@/components/pages/private/ClientView";
import CompanyDetails from "@/components/pages/public/CompanyDetails";
import EmailConfirmation from '@/components/pages/public/EmailConfirmation';
import Error_404 from "@/components/pages/public/Error_404";
import Error_500 from "@/components/pages/public/Error_500";
import Home from '@/components/pages/private/Home';
import Index from '@/components/pages/public/Index';
import Inventory from '@/components/pages/private/Inventory';
import LicenseConfig from "@/components/pages/private/LicenseConfig";
import LicenseCreate from "@/components/pages/private/LicenseCreate";
import LicenseUserMessages from "@/components/pages/private/LicenseUserMessages";
import Products from '@/components/pages/private/Products';
import ProductGroups from '@/components/pages/private/ProductGroups';
import ProductCreate from '@/components/pages/private/ProductCreate';
import ProductView from '@/components/pages/private/ProductView';
import ResetPassword from '@/components/pages/public/ResetPassword';
import SearchResults from "@/components/pages/private/SearchResults";
import SignUp from '@/components/pages/public/SignUp';
import SystemAdministration from '@/components/pages/private/SystemAdministration';

Vue.use(VueRouter);
Vue.config.productionTip = false
//Vue.prototype.$API_ADDRESS = 'http://localhost:8443/v2'
Vue.prototype.$API_ADDRESS = 'https://api.newspirit.com.br/v2'
Vue.prototype.$APP_URL = 'https://newspirit.com.br' //'http://187.181.175.237:8080'
Vue.use(VueMask)
Vue.use(require('vue-moment'));

Vue.mixin({
      methods: {
            //TOAST
            showMessage: (message, type) => {
                  let x = document.getElementById("snackbar");
                  if (type === 'ERROR') {
                        x.style.backgroundColor = '#d61809';
                  } else if (type === 'INFO') {
                        x.style.backgroundColor = '#0985ae';
                  } else if (type === 'WARN') {
                        x.style.backgroundColor = '#ffbb33';
                        x.style.color = '#333';
                  }
                  x.innerText = message;
                  x.className = "show";

                  setTimeout(function () {
                        x.className = x.className.replace("show", "");
                        x.style.backgroundColor = '#333';
                        x.style.color = '#fff';
                        document.getElementById("snackbar").innerText = '';
                  }, 4500);
            },

            turnLoaderOn: () => {
                  document.getElementById("loader").style.display = "block";
            },

            turnLoaderOff: () => {
                  document.getElementById("loader").style.display = "none";
            }
      }
})

const router = new VueRouter({
      mode: 'history',
      routes: [
            {path: '/', name: 'Index', component: Index},
            {path: '/404', name: '404', component: Error_404},
            {path: '/500', name: '500', component: Error_500},
            {path: '/catalogs', name: 'Catalogs', component: Catalogs},
            {path: '/catalogView', name: 'CatalogView', component: CatalogView},
            {path: '/catalogViewInternal', name: 'CatalogViewInternal', component: CatalogViewInternal},
            {path: '/catalogCreate', name: 'CatalogCreate', component: CatalogCreate},
            {path: '/catalogProductView', name: 'CatalogProductView', component: CatalogProductView},
            {path: '/checkoutOrder', name: 'CheckoutOrder', component: CheckoutOrder},
            {path: '/clients', name: 'Clients', component: Clients},
            {path: '/clientCreate', name: 'ClientCreate', component: ClientCreate},
            {path: '/clientView', name: 'ClientView', component: ClientView},
            {path: '/clientOrders', name: 'ClientOrders', component: ClientOrders},
            {path: '/clientOrderCreate', name: 'ClientOrderCreate', component: ClientOrderCreate},
            {path: '/clientOrderView', name: 'ClientOrderView', component: ClientOrderView},
            {path: '/companyDetails', name: 'CompanyDetails', component: CompanyDetails},
            {path: '/emailConfirmation', name: 'EmailConfirmation', component: EmailConfirmation},
            {path: '/home', name: 'Home', component: Home},
            {path: '/inventory', name: 'Inventory', component: Inventory},
            {path: '/licenseConfig', name: 'LicenseConfig', component: LicenseConfig},
            {path: '/licenseCreate', name: 'LicenseCreate', component: LicenseCreate},
            {path: '/licenseUserMessages', name: 'LicenseUserMessages', component: LicenseUserMessages},
            {path: '/products', name: 'Products', component: Products},
            {path: '/productGroups', name: 'ProductGroups', component: ProductGroups},
            {path: '/productCreate', name: 'ProductCreate', component: ProductCreate},
            {path: '/productView', name: 'ProductView', component: ProductView},
            {path: '/resetPassword', name: 'ResetPassword', component: ResetPassword},
            {path: '/signUp', name: 'SignUp', component: SignUp},
            {path: '/searchResults', name: 'SearchResults', component: SearchResults},
            {path: '/systemAdministration', name: 'SystemAdministration', component: SystemAdministration},
      ]
})

new Vue({
      router,
      render: h => h(App),
}).$mount('#app')



