<template>
    <div>
        <MenuBar/>
        <div class="contentOnNavBar">
            <SideMenu/>
            <div style="padding: 0.5rem; width: 100%">
                <div class="pageTitle">Lista de Pedidos</div>

                <div style="padding: 1rem 0 0.5rem 0; width: 100%;">
                    <button class="btn btn-success btn-sm" @click="$router.push({name: 'ClientOrderCreate'})">Novo Pedido</button>
                    <button class="btn btn-danger btn-sm" style="float: right;" data-bs-toggle="modal" data-bs-target="#confirmDelete"
                            :disabled="selectedOrders.length === 0">Excluir Selecionados
                    </button>
                </div>

                <div id="no-more-tables">
                    <table class="table table-sm" style="padding-top: 0.5rem;" v-if="clientOrders">
                        <thead>
                        <tr>
                            <th scope="col">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" onclick="">
                                </div>
                            </th>
                            <th scope="col">Cliente
                                <i class="fas fa-sort" v-if="!$refs.pagination.sortFieldsContainsField('client.name', null)" @click="$refs.pagination.addSortField('client.name')"></i>
                                <i class="fas fa-sort-up" v-if="$refs.pagination.sortFieldsContainsField('client.name', 'ASC')" @click="$refs.pagination.addSortField('client.name')"></i>
                                <i class="fas fa-sort-down" v-if="$refs.pagination.sortFieldsContainsField('client.name', 'DESC')" @click="$refs.pagination.addSortField('client.name')"></i>
                            </th>
                            <th scope="col">Email
                                <i class="fas fa-sort" v-if="!$refs.pagination.sortFieldsContainsField('client.email', null)" @click="$refs.pagination.addSortField('client.email')"></i>
                                <i class="fas fa-sort-up" v-if="$refs.pagination.sortFieldsContainsField('client.email', 'ASC')" @click="$refs.pagination.addSortField('client.email')"></i>
                                <i class="fas fa-sort-down" v-if="$refs.pagination.sortFieldsContainsField('client.email', 'DESC')" @click="$refs.pagination.addSortField('client.email')"></i>
                            </th>
                            <th scope="col">Celular</th>
                            <th scope="col">Data
                                <i class="fas fa-sort" v-if="!$refs.pagination.sortFieldsContainsField('initialDate', null)" @click="$refs.pagination.addSortField('initialDate')"></i>
                                <i class="fas fa-sort-up" v-if="$refs.pagination.sortFieldsContainsField('initialDate', 'ASC')" @click="$refs.pagination.addSortField('initialDate')"></i>
                                <i class="fas fa-sort-down" v-if="$refs.pagination.sortFieldsContainsField('initialDate', 'DESC')" @click="$refs.pagination.addSortField('initialDate')"></i>
                            </th>
                            <th scope="col">Status
                                <i class="fas fa-sort" v-if="!$refs.pagination.sortFieldsContainsField('status', null)" @click="$refs.pagination.addSortField('status')"></i>
                                <i class="fas fa-sort-up" v-if="$refs.pagination.sortFieldsContainsField('status', 'ASC')" @click="$refs.pagination.addSortField('status')"></i>
                                <i class="fas fa-sort-down" v-if="$refs.pagination.sortFieldsContainsField('status', 'DESC')" @click="$refs.pagination.addSortField('status')"></i>
                            </th>
                            <th scope="col">Total
                                <i class="fas fa-sort" v-if="!$refs.pagination.sortFieldsContainsField('total', null)" @click="$refs.pagination.addSortField('total')"></i>
                                <i class="fas fa-sort-up" v-if="$refs.pagination.sortFieldsContainsField('total', 'ASC')" @click="$refs.pagination.addSortField('total')"></i>
                                <i class="fas fa-sort-down" v-if="$refs.pagination.sortFieldsContainsField('total', 'DESC')" @click="$refs.pagination.addSortField('total')"></i>
                            </th>
                            <th scope="col" style="width: 5.7rem">Ações</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(clientOrder, index) in clientOrders" :key="index">
                            <td data-title="Selecionar" class="align-middle">
                                <div class="form-check">
                                    <input class="form-check-input" :value="clientOrder.id" type="checkbox" v-model="selectedOrders">
                                </div>
                            </td>
                            <td data-title="Nome" class="align-middle">{{ clientOrder.client.name }}</td>
                            <td data-title="Email" class="align-middle">{{ clientOrder.client.email }}</td>
                            <td data-title="Celular" class="align-middle">{{ clientOrder.client.privatePhone }}</td>
                            <td data-title="Data" class="align-middle">{{ clientOrder.initialDate | moment("DD/MM/YYYY HH:mm") }}</td>
                            <td data-title="Status" class="align-middle">{{ orderStatusPt(clientOrder.status) }}</td>
                            <!--<td data-title="Itens" class="align-middle">{{ clientOrder.products.length }}</td>-->
                            <td data-title="Valor" class="align-middle">R$ {{ clientOrder.total.toLocaleString() }}</td>
                            <td class="align-middle">
                                <div class="btn-group " role="group">
                                    <button id="btnGroupDrop1" type="button" class="btn btn-warning btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                        Ações
                                    </button>
                                    <ul class="dropdown-menu" aria-labelledby="btnGroupDrop1" style="min-width: unset !important; padding: 0 !important">
                                        <li><a class="dropdown-item sm" @click="$router.push({name: 'ClientOrderView', query: {'id': clientOrder.id}})" style="cursor: pointer">
                                            <i class="fas fa-search fa-sm" style="margin-right: 0.5rem"></i>Ver</a>
                                        </li>
                                        <li><a class="dropdown-item sm" style="cursor: pointer"><i class="fas fa-pen fa-sm" style="margin-right: 0.5rem"></i>Editar</a></li>
                                        <li><a class="dropdown-item sm" @click="selectedOrders = []; selectedOrders.push(clientOrder.id)" data-bs-toggle="modal" data-bs-target="#confirmDelete" style="cursor: pointer">
                                            <i class="fas fa-trash fa-sm" style="margin-right: 0.5rem"></i>Excluir</a></li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <Pagination ref="pagination" v-on:page-clicked="(s) => this.clientOrders = s" endpoint-list="/clientOrder/list/" endpoint-count="/clientOrder/count"/>
                <ConfirmDelete v-on:done="$refs.pagination.getObjects(-1)" :objects-to-exclude="selectedOrders"
                               :message="'Tem certeza que deseja excluir o pedido selecionado?'" :endpoint="'/clientOrder'"/>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
import MenuBar from '../../template/MenuBar'
import SideMenu from '../../template/SideMenu'
import Footer from '../../template/Footer'
import Pagination from '@/components/pages/components/Pagination.vue'
import ConfirmDelete from "@/components/pages/components/ConfirmDelete";
import axios from "axios";

export default {
    components: {
        ConfirmDelete,
        MenuBar,
        SideMenu,
        Footer,
        Pagination
    },

    data: function () {
        return {
            clientOrders: null,
            selectedOrders: [],
        }
    },

    mounted() {
        if (axios.defaults.headers.common["Authorization"] == undefined || axios.defaults.headers.common["Authorization"] == '') {
            this.$router.push({name: 'Index'})
        }
    },

    methods: {
        orderStatusPt(status) {
            switch (status) {
                case 'RECEIVED':
                    return 'Recebida';
                case 'SEEN':
                    return 'Visto';
                case 'PROCESSED':
                    return 'Processado';
                case 'DELIVERED':
                    return 'Entregue';
                case 'FINISHED':
                    return 'Finalizado';
            }
            return '';
        }
    }
}
</script>

<style scoped>
</style>