<template>
    <nav aria-label="Page navigation example">
        <ul class="pagination justify-content-center">
            <li class="page-item" v-if="currentPage > 0" @click="getObjects(currentPage-1)">
                <a class="page-link" href="#" tabindex="-1" aria-disabled="true">&#60;</a>
            </li>

            <li class="page-item" v-for="(n, index) in totalPages" :key="index">
                <a class="page-link" href="#" @click="getObjects(index)">{{ index + 1 }}</a>
            </li>

            <li class="page-item" v-if="currentPage+1 < totalPages" @click="getObjects(currentPage+1)">
                <a class="page-link" href="#">&#62;</a>
            </li>
        </ul>
    </nav>
</template>

<script>
import axios from "axios";

export default {
    name: "Pagination",

    props: {
        endpointList: null,
        endpointCount: null,
        searchAtStart: {
            type: Boolean,
            default: true
        }
    },

    watch: {
        sortFields() {
            this.currentPage = -1
            this.getObjects(0)
        }
    },

    mounted() {
        if(this.searchAtStart){
            this.getObjects(0)
            this.countObjects()
        }
    },

    data: function () {
        return {
            currentPage: -1,
            totalPages: null,
            count: null,
            sortFields: []
        }
    },

    methods: {
        getObjects: function (page) {
            if (page !== this.currentPage || page < 0) {
                let url = this.$API_ADDRESS + this.endpointList + (page < 0 ? 0 : page) + (this.sortFields.length > 0 ? '?orderBy=' + encodeURI(JSON.stringify(this.sortFields)) : '');
                axios.get(url)
                    .then(response => {
                        this.currentPage = (page < 0 ? 0 : page);
                        this.$emit('page-clicked', response.data);
                    })
            }
        },

        countObjects: function () {
            axios.get(this.$API_ADDRESS + this.endpointCount)
                .then(response => {
                    this.count = response.data;
                    this.totalPages = Math.ceil(this.count / 45);
                })
        },

        addSortField(fieldName) {
            let sortField = null
            if (!this.sortFieldsContainsField(fieldName, null)) {
                sortField = {}
                sortField.name = fieldName
                sortField.order = 'ASC'
            } else {
                this.sortFields.forEach(s => {
                    if (s.name === fieldName) {
                        sortField = s
                        this.sortFields.splice(this.sortFields.indexOf(s), 1)
                    }
                })
                sortField.order = sortField.order === 'ASC' ? 'DESC' : 'ASC'
            }
            this.sortFields.unshift(sortField)
            if (this.sortFields.length > 2) this.sortFields.pop();
        },

        sortFieldsContainsField(fieldName, order) {
            let result = false
            this.sortFields.forEach(s => {
                if (s.name == fieldName) {
                    if (order !== null) {
                        result = (s.order == order)
                    } else {
                        result = true
                    }
                }
            })
            return result
        },
    }
}
</script>

<style scoped>

</style>