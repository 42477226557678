<template>
    <select class="btn btn-sm " aria-label="Classificação" style="background-color: white; width: 100%; border: 1px solid #ced4da"
            :name="name" :value="value" v-on:input="$emit('input', $event.target.value)">>
        <option value="FISICAL_PERSON">Pessoa Física</option>
        <option value="LEGAL_PERSON">Pessoa Jurídica</option>
        <option value="FOREIGNER">Estrangeiro</option>
    </select>
</template>

<script>
export default {
    props: ['name', 'value']
}
</script>

<style scoped>

</style>