<template>
    <div style="display: inline-block">
        <!-- Button trigger modal -->
        <button type="button" class="btn btn-success btn-sm " data-bs-toggle="modal" data-bs-target="#newProductGroup">
            Novo Grupo de Produtos
        </button>

        <!-- Modal -->
        <div class="modal fade" id="newProductGroup" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <form class="modal-content" @submit.prevent="createGroup">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Novo Grupo de Produtos</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="alert alert-danger alert-dismissible fade show" role="alert" style="margin: 1rem; display: none;" id="errorAlert">
                        Erro tentando criar o seu novo Grupo de Produtos
                    </div>
                    <div class="modal-body">
                        <div class="mb-2">
                            <label for="productGroupName" class="form-label">Nome do Grupo de Produtos:</label>
                            <input type="text" required="required" class="form-control form-control-sm" id="productGroupName" v-model="newGroup.name">
                        </div>
                        <div class="mb-2">
                            <label for="productGroupColor" class="form-label">Cor:</label><br/>
                            <input type="color" required id="productGroupColor" onchange="clickColor(0, -1, -1, 5)" value="#ff0000" style="width:50%;" v-model="newGroup.color">
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id="cancelBtn">Cancelar</button>
                        <button type="submit" class="btn btn-info">Cadastrar</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: "NewProductGroup",

    data: function () {
        return {
            newGroup: {
                name: null,
                color: null
            }
        }
    },

    methods: {

        mounted(){
            this.newGroup.id = 0
            this.newGroup.name = null
            this.newGroup.color = null
        },

        createGroup: function () {
            if (this.newGroup.color === null || this.newGroup.name === null) {
                this.showMessage("Cor ou Nome não preenchidos", 'WARN');
                return
            }

            axios.post(this.$API_ADDRESS + '/productGroup', this.newGroup)
                .then(response => {
                    this.newGroup.id = response.data
                    this.newGroup.cont = 0
                    this.$emit('group-created', this.newGroup)
                    document.getElementById('cancelBtn').click();
                })
        }
    }
}
</script>

<style scoped>

</style>