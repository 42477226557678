<template>
    <div class="bg-image">
        <BigHeader/>
        <div class="vCenterWrapper  contentOnBigHeader">
            <div class="">
                <form id="signForm" style="width: 26rem; font-family: roboto; margin: 0 auto;" @submit.prevent="doSignUp">
                    <div style="text-align: center; width: 100%; margin-bottom: 1rem;">
                        <div class="text-primary" style="font-weight: bold; font-size: 1.6rem;">Cadastre-se Gratuitamente!</div>
                        <span>Já possui Cadastro? <span @click="$router.push({name: 'Index'})" style="cursor: pointer; color: #0464e0; font-weight: bold;">Faça Login!</span></span>
                    </div>

                    <div class="alert alert-danger" role="alert" style="display: none;" id="signErrorAlert"></div>

                    <div class="mb-3">
                        <label for="name" class="form-label ">Nome Completo</label>
                        <input type="text" class="form-control " placeholder="Digite seu nome" id="name" required v-model="newUser.name"
                               minlength="3">
                    </div>
                    <div class="mb-3">
                        <label for="companyName" class="form-label">Nome da Empresa</label>
                        <input type="text" class="form-control form-control" id="companyName" required v-model="newUser.companyName"
                               minlength="3">
                    </div>
                    <div class="mb-3">
                        <label for="email" class="form-label">Email</label>
                        <input type="email" class="form-control form-control" id="email" required v-model="newUser.email">
                    </div>
                    <div class="mb-3">
                        <label for="password" class="form-label">Senha</label>
                        <input type="password" class="form-control form-control" id="password" required minlength="6" v-model="newUser.password">
                    </div>
                    <div class="mb-3">
                        <label for="password2" class="form-label">Confirmar Senha</label>
                        <input type="password" class="form-control form-control" id="password2" required minlength="6" v-model="newUser.confirmPassword">
                    </div>
                    <div class="mb-1 form-check" style="text-align: justify">
                        <input type="checkbox" class="form-check-input" id="exampleCheck1" required v-model="newUser.terms">
                        <label class="form-check-label" for="exampleCheck1" style="font-size: 0.8rem;">Li e concordo com as <a href="#">Políticas de Privacidade</a> e os <a href="#">Termos e Condições de Uso.</a></label>
                    </div>
                    <div style="width: 100%; text-align: center; line-height: normal;">
                        <button type="submit" class="btn btn-info" style="margin: 0 auto;">Cadastrar</button>
                    </div>
                </form>
            </div>
        </div>
        <Footer/>
    </div>


</template>

<script>
import BigHeader from '../../template/BigHeader'
import Footer from '../../template/Footer'
import axios from "axios";

export default {
    components: {
        BigHeader,
        Footer
    },

    data: function () {
        return {
            newUser: {
                name: '',
                companyName: '',
                email: '',
                password: '',
                confirmPassword: '',
                terms: false
            }
        }
    },

    methods: {
        doSignUp: function () {
            if (this.newUser.password !== this.newUser.confirmPassword) {
                document.getElementById('signErrorAlert').textContent = 'Senhas não conferem'
                document.getElementById('signErrorAlert').style.display = 'block'
                return
            }
            document.getElementById('signErrorAlert').style.display = 'none'
            delete this.newUser.terms
            delete this.newUser.confirmPassword

            axios.post(this.$API_ADDRESS + '/license/create', this.newUser)
                .then(() => {
                    this.$router.push({name: 'EmailConfirmation', query: {email: this.newUser.email}});
                })
        }
    }
}
</script>

<style scoped>
.form-label {
    margin-bottom: 0;
}

#signForm {
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 1.2rem;
    padding: 1rem;
    box-shadow: 5px 2px 7px #000000;
}
</style>