<template>
    <div>
        <MenuBar/>
        <div class="contentOnNavBar">
            <SideMenu/>
            <div style="padding: 0.5rem; width: 100%">
                <div class="pageTitle">Lista de Produtos</div>

                <div style="padding: 1rem 0 0.5rem 0; width: 100%;">
                    <button class="btn btn-success btn-sm" @click="$router.push({name: 'ProductCreate'})">Novo Produto</button>
                    <div class="btn-group" role="group" style="float: right;">
                        <button id="btnGroupDrop2" type="button" class="btn btn-info btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" :disabled="selectedProducts.length === 0">
                            Selecionados
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="btnGroupDrop2" style="min-width: unset !important; padding: 0 !important">
                            <li><a class="dropdown-item sm" style="cursor: pointer" @click="startCatalog(null)">
                                <i class="fas fa-plus-circle fa-sm" style="margin-right: 0.5rem"></i>Iniciar Catálogo</a>
                            </li>
                            <li><a class="dropdown-item sm" data-bs-toggle="modal" data-bs-target="#searchCatalogDialog" style="cursor: pointer">
                                <i class="fas fa-plus-square fa-sm" style="margin-right: 0.5rem"></i>Incluir em Catálogo</a>
                            </li>
                            <li><a class="dropdown-item sm" data-bs-toggle="modal" data-bs-target="#confirmDelete" style="cursor: pointer">
                                <i class="fas fa-trash fa-sm" style="margin-right: 0.5rem"></i>Excluir</a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div id="no-more-tables">
                    <table class="table table-sm cf" style="padding-top: 0.5rem;" v-if="products">
                        <thead class="cf">
                        <tr>
                            <th scope="col">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" @click="selectAllOrNone">
                                </div>
                            </th>
                            <th scope="col">Nome
                                <i class="fas fa-sort" v-if="!$refs.pagination.sortFieldsContainsField('name', null)" @click="$refs.pagination.addSortField('name')"></i>
                                <i class="fas fa-sort-up" v-if="$refs.pagination.sortFieldsContainsField('name', 'ASC')" @click="$refs.pagination.addSortField('name')"></i>
                                <i class="fas fa-sort-down" v-if="$refs.pagination.sortFieldsContainsField('name', 'DESC')" @click="$refs.pagination.addSortField('name')"></i>
                            </th>
                            <th scope="col">Grupos</th>
                            <th scope="col">Código
                                <i class="fas fa-sort" v-if="!$refs.pagination.sortFieldsContainsField('internalCode', null)" @click="$refs.pagination.addSortField('internalCode')"></i>
                                <i class="fas fa-sort-up" v-if="$refs.pagination.sortFieldsContainsField('internalCode', 'ASC')" @click="$refs.pagination.addSortField('internalCode')"></i>
                                <i class="fas fa-sort-down" v-if="$refs.pagination.sortFieldsContainsField('internalCode', 'DESC')" @click="$refs.pagination.addSortField('internalCode')"></i>
                            </th>
                            <th scope="col">Descrição</th>
                            <th scope="col" style="width: 5.7rem">Ações</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(product, index) in products" :key="index">
                            <td data-title="Selecionar" class="align-middle">
                                <div class="form-check">
                                    <input class="form-check-input" :value="product.id" type="checkbox" v-model="selectedProducts">
                                </div>
                            </td>
                            <td data-title="Nome" class="align-middle">{{ product.name }}</td>
                            <td data-title="Grupos" class="align-middle">{{ getProductGroups(product) }}
                                <pre v-if="product.productGroups.length === 0"></pre>
                            </td>
                            <td data-title="Código" class="align-middle">{{ product.internalCode }}</td>
                            <td data-title="Descrição" class="align-middle">
                                <span style="text-overflow: ellipsis;overflow: hidden; display: inline-block; " id="descriptionProduct">{{ product.description }}</span>
                            </td>
                            <td data-title="Ações" class="align-middle">
                                <div class="btn-group " role="group">
                                    <button id="btnGroupDrop1" type="button" class="btn btn-warning btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                        Ações
                                    </button>
                                    <ul class="dropdown-menu" aria-labelledby="btnGroupDrop1" style="min-width: unset !important; padding: 0 !important">
                                        <li><a class="dropdown-item sm" @click="$router.push({name: 'ProductView', query:{id: product.id}})" style="cursor: pointer"> <i class="far fa-eye fa-sm" style="margin-right: 0.5rem"></i>Ver</a></li>
                                        <li><a class="dropdown-item sm" @click="$router.push({name: 'ProductCreate', query:{id: product.id}})" style="cursor: pointer"> <i class="fas fa-pen fa-sm" style="margin-right: 0.5rem"></i>Editar</a></li>
                                        <li><a class="dropdown-item sm" @click="$refs.shareDialog.urlToShare = $APP_URL + '/ProductWatch?id='+product.id" data-bs-toggle="modal" data-bs-target="#shareChooseDialog" style="cursor: pointer"><i class="fas fa-share-alt fa-sm" style="margin-right: 0.5rem"></i>Compartilhar</a></li>
                                        <li><a class="dropdown-item sm" @click="startCatalog(product.id)" style="cursor: pointer"><i class="fas fa-plus-circle fa-sm" style="margin-right: 0.5rem"></i>Iniciar Catálogo</a></li>
                                        <li><a class="dropdown-item sm" data-bs-toggle="modal" data-bs-target="#searchCatalogDialog" style="cursor: pointer"><i class="fas fa-plus-square fa-sm" style="margin-right: 0.5rem"></i>Incluir em Catálogo</a></li>
                                        <li><a class="dropdown-item sm" @click="setSelectedProduct(product.id)" data-bs-toggle="modal" data-bs-target="#confirmDelete" style="cursor: pointer">
                                            <i class="fas fa-trash fa-sm" style="margin-right: 0.5rem"></i>Excluir</a></li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <Pagination ref="pagination" v-on:page-clicked="(p) => this.products = p" endpoint-list="/product/list/" endpoint-count="/product/count"/>
                <SearchCatalogToIncludeProduct :products="selectedProducts"/>
                <ConfirmDelete v-on:done="$refs.pagination.getObjects(-1)" :objects-to-exclude="selectedProducts"
                               :message="'Tem certeza que deseja excluir o produto selecionado?'" :endpoint="'/product'"/>
                <ShareChooseDialog ref="shareDialog"/>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
import MenuBar from '../../template/MenuBar'
import SideMenu from '../../template/SideMenu'
import Footer from '../../template/Footer'
import Pagination from '@/components/pages/components/Pagination.vue'
import SearchCatalogToIncludeProduct from "@/components/pages/components/SearchCatalogToIncludeProduct";
import ConfirmDelete from "@/components/pages/components/ConfirmDelete";
import ShareChooseDialog from "@/components/pages/components/ShareChooseDialog";
import axios from "axios";

export default {
    components: {
        ShareChooseDialog,
        ConfirmDelete,
        SearchCatalogToIncludeProduct,
        MenuBar,
        SideMenu,
        Footer,
        Pagination
    },

    data: function () {
        return {
            products: null,
            selectedProducts: [],
        }
    },

    mounted() {
        if (axios.defaults.headers.common["Authorization"] == undefined || axios.defaults.headers.common["Authorization"] == '') {
            this.$router.push({name: 'Index'})
        }
    },

    methods: {
        selectAllOrNone() {
            if (this.selectedProducts.length > 0) {
                this.selectedProducts = []
            } else {
                this.selectedProducts = []
                this.products.forEach(p => {
                    this.selectedProducts.push(p.id)
                })
            }
        },

        startCatalog(selectedProductId) {
            if (selectedProductId !== undefined && selectedProductId !== null) {
                this.$router.push({'name': 'CatalogCreate', query: {'productIds': encodeURI('[' + selectedProductId + ']')}})
            } else if (this.selectedProducts.length > 0) {
                this.$router.push({'name': 'CatalogCreate', query: {'productIds': encodeURI(JSON.stringify(this.selectedProducts))}})
            }
        },

        setSelectedProduct(productId) {
            this.selectedProducts = []
            this.selectedProducts.push(productId)
        },

        getProductGroups(product) {
            let s = '';
            product.productGroups.forEach(g => {
                s += g.name + '; ';
            })
            return s;
        }

    },

}
</script>

<style scoped>
@media (min-width: 700px) {
    #descriptionProduct {
        width: 200px;
    }
}

@media (max-width: 700px) {
    #descriptionProduct {
        width: 50%;
    }
}

</style>