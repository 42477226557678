<template>
    <div class="bg-image">
        <BigHeader/>

        <div class="vCenterWrapper contentOnBigHeader">
            <div></div>
            <form id="forgotPasswordForm" style="width: 26rem; font-family: roboto; margin: 0 auto;" @submit.prevent="doForgetPassword">
                <div style="text-align: center; width: 100%; margin-bottom: 1rem;">
                    <div class="text-primary" style="font-weight: bold; font-size: 1.6rem;">Recuperar Senha</div>
                    <span>Lembrou a Senha? <span @click="$router.push({name: 'Index'})" style="cursor: pointer; color: #0464e0; font-weight: bold;">Faça Login!</span></span>
                </div>

                <div class="alert alert-danger" role="alert" style="display: none;" id="resetErrorAlert"></div>

                <div class="mb-3">
                    <label for="forgotEmail" class="form-label">Email:</label>
                    <input type="email" class="form-control" placeholder="Digite seu email" required id="forgotEmail" v-model="body.email">
                </div>
                <div class="mb-3 text-success" v-if="msg">Email de recuperação de senha enviado!</div>
                <div style="width: 100%; text-align: center; line-height: normal;">
                    <button type="submit" class="btn btn-info" style="margin: 0 auto;">Enviar</button>
                </div>
            </form>
        </div>

        <Footer/>
    </div>


</template>

<script>
import BigHeader from '../../template/BigHeader'
import Footer from '../../template/Footer'
import axios from "axios";

export default {
    components: {
        BigHeader,
        Footer
    },

    data: function () {
        return {
            body: {
                email: ''
            },
            msg: false
        }
    },

    methods: {
        doForgetPassword: function () {
            axios.post(this.$API_ADDRESS + '/user/forgetPassword', this.body)
                .then(() => {
                    this.$router.push({name: 'EmailConfirmation', query: {email: this.body.email}});
                })
        }
    }
}
</script>

<style scoped>
.form-label {
    margin-bottom: 0;
}


#forgotPasswordForm {
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 1.2rem;
    padding: 1rem;
    box-shadow: 5px 2px 7px #000000;
}
</style>