<template>
    <div>
        <MenuBar ref="menuBar"/>
        <div class="contentOnNavBar">
            <SideMenu/>
            <div style="padding: 0.5rem; width: 100%">
                <div class="pageTitle">Mensagens</div>
                <div class="pageTitleDetails">Abaixo você encontra uma lista de mensagens geradas pelo sistema.</div>

                <div class="btn-group" role="group" style="float: right;">
                    <button id="btnGroupDrop2" type="button" class="btn btn-info btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" :disabled="selectedMessages.length === 0">
                        Selecionadas
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="btnGroupDrop2" style="min-width: unset !important; padding: 0 !important">
                        <li><a class="dropdown-item sm" style="cursor: pointer">
                            <i class="fas fa-plus-circle fa-sm" style="margin-right: 0.5rem"></i>Marcar Como Vistas</a>
                        </li>
                        <li><a class="dropdown-item sm" data-bs-toggle="modal" data-bs-target="#confirmDelete" style="cursor: pointer">
                            <i class="fas fa-trash fa-sm" style="margin-right: 0.5rem"></i>Excluir</a>
                        </li>
                    </ul>
                </div>

                <div id="no-more-tables">
                    <table class="table table-sm" style="padding-top: 0.5rem;" v-if="messages">
                        <thead>
                        <tr>
                            <th scope="col">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox">
                                </div>
                            </th>
                            <th scope="col">Assunto
                                <i class="fas fa-sort" v-if="!$refs.pagination.sortFieldsContainsField('name', null)" @click="$refs.pagination.addSortField('name')"></i>
                                <i class="fas fa-sort-up" v-if="$refs.pagination.sortFieldsContainsField('name', 'ASC')" @click="$refs.pagination.addSortField('name')"></i>
                                <i class="fas fa-sort-down" v-if="$refs.pagination.sortFieldsContainsField('name', 'DESC')" @click="$refs.pagination.addSortField('name')"></i>
                            </th>
                            <th scope="col">Status
                                <i class="fas fa-sort" v-if="!$refs.pagination.sortFieldsContainsField('email', null)" @click="$refs.pagination.addSortField('email')"></i>
                                <i class="fas fa-sort-up" v-if="$refs.pagination.sortFieldsContainsField('email', 'ASC')" @click="$refs.pagination.addSortField('email')"></i>
                                <i class="fas fa-sort-down" v-if="$refs.pagination.sortFieldsContainsField('email', 'DESC')" @click="$refs.pagination.addSortField('email')"></i>
                            </th>
                            <th scope="col">Data</th>
                            <th scope="col" style="width: 5.7rem">Ações</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(message, index) in messages" :key="index">
                            <td data-title="Selecionar" class="align-middle">
                                <div class="form-check">
                                    <input class="form-check-input" :value="message.id" type="checkbox" v-model="selectedMessages">
                                </div>
                            </td>
                            <td data-title="Assunto" class="align-middle">{{ message.subject }}</td>
                            <td data-title="Status" class="align-middle">{{ message.status == 'SEEN' ? 'Vista' : 'Recebida' }}</td>
                            <td data-title="Data" class="align-middle">{{ message.creationDate | moment("DD/MM/YYYY HH:mm") }}</td>
                            <td class="align-middle">
                                <div class="btn-group " role="group">
                                    <button id="btnGroupDrop1" type="button" class="btn btn-warning btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                        Ações
                                    </button>
                                    <ul class="dropdown-menu" aria-labelledby="btnGroupDrop1" style="min-width: unset !important; padding: 0 !important">
                                        <li><a class="dropdown-item sm" style="cursor: pointer">
                                            <i class="fas fa-search fa-sm" style="margin-right: 0.5rem"></i>Ver</a>
                                        </li>
                                        <li><a class="dropdown-item sm" style="cursor: pointer" @click="selectedMessages = [message.id]; markSeen()">
                                            <i class="fas fa-plus-circle fa-sm" style="margin-right: 0.5rem"></i>Marcar Vista</a>
                                        </li>
                                        <li><a class="dropdown-item sm" data-bs-toggle="modal" data-bs-target="#confirmDelete" style="cursor: pointer">
                                            <i class="fas fa-trash fa-sm" style="margin-right: 0.5rem"></i>Excluir</a></li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <Pagination ref="pagination" v-on:page-clicked="(c) => this.messages = c" endpoint-list="/licenseUserMessage/list/" endpoint-count="/licenseUserMessage/count"/>
                <ConfirmDelete v-on:done="$refs.pagination.getObjects(-1)" :objects-to-exclude="selectedMessages"
                               :message="'Tem certeza que deseja excluir a mensagem selecionada?'" :endpoint="'/userMessage'"/>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
import MenuBar from '../../template/MenuBar'
import SideMenu from '../../template/SideMenu'
import Footer from '../../template/Footer'
import Pagination from "@/components/pages/components/Pagination";
import ConfirmDelete from "@/components/pages/components/ConfirmDelete";
import axios from "axios";

export default {
    components: {
        ConfirmDelete,
        Pagination,
        MenuBar,
        SideMenu,
        Footer,
    },

    created() {
    },

    data: function () {
        return {
            messages: null,
            selectedMessages: [],
        }
    },

    mounted() {
        if (axios.defaults.headers.common["Authorization"] == undefined || axios.defaults.headers.common["Authorization"] == '') {
            this.$router.push({name: 'Index'})
        }
    },

    methods: {
        markSeen() {
            axios.post(this.$API_ADDRESS + '/licenseUserMessage/markRead?idList=' + encodeURI(JSON.stringify(this.selectedMessages)))
                .then(() => {
                    this.$refs.menuBar.getNewMessages();
                    this.messages.forEach(m => {
                        if (this.selectedMessages.indexOf(m.id) >= 0) {
                            m.status = 'SEEN'
                        }
                    })
                })
        }
    },
}
</script>

<style scoped>
</style>