<template>
    <nav class="navbar navbar-expand-lg navbar-dark bg-primary" style="height: 50px;">
        <div id="navbarContent" class="container-fluid" style="padding-left: 0;">

            <router-link id="brandView" class="navbar-brand" :to="{ name: 'Home'}" style=" text-align: center;">
                <img src="../../assets/img/logo.png" alt="New Spirit Logo" height="30" width="auto" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Início"/>
                <span id="newSpiritTitle" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Início">New Spirit</span>
            </router-link>

            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse bg-primary" id="navbarSupportedContent" style="color: white; margin: 0 auto; text-align: center; z-index: 9999999">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0"></ul>
                <input id="inputSearchBar" class="form-control form-control-sm me-2" type="search" placeholder="Pesquisar" aria-label="Pesquisar" style="max-width: 12rem"/>
                <button class="btn btn-sm btn-outline-light me-4" type="submit">Pesquisar</button>

                <div class="me-2">{{ this.loggedUser.name }}</div>
                <div id="inputSearchBarMsg" class="me-2 cursorPointer" @click="$router.push({name: 'LicenseUserMessages'})" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Mensagens">
                    <img src="../../assets/img/envelope_white.svg" height="23" width="auto"/>
                    <span class="badge bg-danger" v-if="messages"
                          style="font-size: 0.8rem; padding: 0.2rem; position: absolute; margin-left: -0.85rem; margin-top: 0.8rem">{{ messages }}</span>
                </div>
                <img src="../../assets/img/settings.svg" @click="$router.push({name: 'LicenseConfig'})" height="22" width="auto" class="me-2 cursorPointer" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Configurações"/>
                <img src="../../assets/img/logout.svg" height="22" width="auto" class="cursorPointer" data-bs-toggle="tooltip"
                     data-bs-placement="bottom" title="Sair" v-on:click="doLogout"/>
            </div>
        </div>
    </nav>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            posts: [],
            errors: [],
            loggedUser: JSON.parse(atob(sessionStorage.loggedUser)),
            timer: null,
            messages: 0,
        }
    },

    mounted() {
        if (sessionStorage.getItem('messages') === null) {
            this.getNewMessages()
        } else {
            this.messages = sessionStorage.getItem('messages');
        }
        this.timer = setInterval(() => {
            this.getNewMessages()
        }, 90000)
    },

    beforeDestroy() {
        clearInterval(this.timer)
    },

    methods: {
        doLogout: function () {
            sessionStorage.clear();
            delete axios.defaults.headers.common["Authorization"]
            this.$router.push({name: 'Index'});
        },

        getNewMessages() {
            //cannot use axios because of loader blocking ui
            let xmlHttp = new XMLHttpRequest();
            xmlHttp.open("GET", this.$API_ADDRESS + '/licenseUserMessage/countReceived', false); // false for synchronous request
            xmlHttp.setRequestHeader("Authorization", axios.defaults.headers.common["Authorization"]);
            xmlHttp.send(null);
            if (xmlHttp.responseText > 0) {
                sessionStorage.setItem('messages', xmlHttp.responseText)
                this.messages = xmlHttp.responseText;
            }
        }
    }
};
</script>

<style scoped>
#newSpiritTitle {
    margin-left: 0.4rem;
    font-family: "righteous";
}

@media screen and (max-width: 992px) {
    #newSpiritTitle {
        display: none;
    }

    #inputSearchBar, #inputSearchBarMsg {
        display: initial;
    }

    #navbarSupportedContent {
        height: 6.5rem;
    }

    #brandView {
        width: 3.2rem;
    }

    #navbarContent {
        padding-top: 0;
        margin-top: -0.8rem;
    }
}

@media screen and (min-width: 992px) {
    #brandView {
        width: 13.2rem;
    }
}

</style>