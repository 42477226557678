<template>
    <div>
        <BigHeader/>
        <div class=" contentOnBigHeader bg-image d-flex justify-content-center align-items-center row">
            <div class="d-flex justify-content-center align-items-center row col-md-12">
                <form id="loginForm" class="position-relative  col-md-12 pb-1 " style="margin-top:3rem;width: 25rem; font-family: roboto; margin: 0 auto;" @submit.prevent="doLogin">
                    <div class="bg-primary position-absolute ms-auto me-auto end-0 start-0" style="top: -3rem; width: 100px; height: 100px; border-radius: 50%; text-align: center;">
                        <i class="bi-person-circle text-white" style="font-size: 50pt;text-align: center; width: 50px; height: 50px; border-radius: 50%"></i>
                    </div>

                    <h2 class="text-center text-primary" style="margin-top: 3rem; margin-bottom: 1rem;color: #003271"><b>Login</b></h2>

                    <p class="bg-danger text-center text-white p-2 rounded" style="display: none" role="alert" id="loginErrorAlert" v-html="msgLoginError"></p>

                    <div class="mb-2 input-group">
                        <span class="bi-person input-group-text"></span>
                        <input type="email" placeholder="Email" class="form-control" required id="loginEmail" :class="{'border-danger': borderError}" v-model="login.email">
                    </div>
                    <div class="mb-1">
                        <div class="input-group flex-nowrap">
                            <span class="bi-key input-group-text"></span>
                            <input type="password" placeholder="Senha" class="form-control" aria-describedby="addon-wrapping" required minlength="6" id="loginPassword" :class="{'border-danger': borderError}" v-model="login.password">
                            <span class="input-group-text bi-eye-fill" type="button" id="addon-wrapping" @click="transformInput"></span>
                        </div>
                    </div>
                    <div class=" flex-row d-flex">
                        <div class="mb-2 form-check ">
                            <input type="checkbox" class="form-check-input" id="keepLogged">
                            <label class="form-check-label" style="color: #0464e0; font-size: 0.9rem" for="keepLogged">Manter-me conectado</label>
                        </div>

                    </div>
                    <div style="width: 100%; text-align: center;">
                        <button type="submit" class="btn btn-primary mt-4 mb-3" style="margin: 0 auto; width: 35%">Entrar</button>
                    </div>

                    <div class=" form-check ps-0" style="text-align: right; flex: 1">
                        <label class="form-check-label" style="text-align: right;">
                            <span @click="$router.push({name: 'ResetPassword'})" style="cursor: pointer; font-weight: bold;color: #0464e0; font-size: 0.9rem">
                                <i class="bi-key-fill me-1"></i>Esqueceu sua senha?</span>
                        </label>
                    </div>
                </form>
                <div class="text-white " style="text-align: center; width: 100%; ">
                    <div class="mt-2" style="color: #003271">Não possui Cadastro? <span @click="$router.push({name: 'SignUp'})" style="cursor: pointer;color: #0464e0; font-weight: bold">Clique aqui!</span></div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
import BigHeader from '../../template/BigHeader'
import Footer from '../../template/Footer'
import axios from "axios";

export default {
    components: {
        BigHeader,
        Footer
    },


    data: function () {
        return {
            login: {
                email: '',
                password: ''
            },
            msgLoginError: 'Usúario ou senha incorretos!',
            borderError: false
        }
    },

    methods: {

        doLogin: function () {
            this.borderError = false
            document.getElementById('loginErrorAlert').style.display = 'none'
            axios.post(this.$API_ADDRESS + '/login', this.login)
                .then(response => {
                    axios.defaults.headers.common = {
                        'Authorization': response.data.authorization,
                    };
                    sessionStorage.setItem('Authorization', response.data.authorization)
                    delete response.data.Authorization
                    sessionStorage.setItem('loggedUser', btoa(JSON.stringify(response.data.loggedUser)))
                    this.$router.push({name: 'Home'});
                })
                .catch(() => {
                    document.getElementById('loginErrorAlert').style.display = 'block'
                    this.borderError = true
                })
        },
        transformInput: function () {
            let item = document.getElementById('loginPassword')
            item.type === 'password' ? item.type = 'text' : item.type = 'password'

        }
    }
}
</script>

<style scoped>
.form-label {
    margin-bottom: 0;
}


#loginForm {
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 1.2rem;
    padding: 1rem;
    box-shadow: 5px 2px 7px #000000;
}

</style>