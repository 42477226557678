<template>
    <select class="btn btn-sm " aria-label="Classificação" style="background-color: white; width: 100%; border: 1px solid #ced4da"
            :name="name" :value="value" v-on:input="$emit('input', $event.target.value)">>
        <option value="ACTIVE">Ativo</option>
        <option value="INACTIVE">Inativo</option>
        <option value="BLOCKED">Bloqueado</option>
    </select>
</template>

<script>
export default {
    props: ['name', 'value']
}
</script>

<style scoped>

</style>