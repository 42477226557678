<template>
    <select class="btn btn-sm btn-outline-secondary" aria-label="Unidade Monetária"
            :name="name" :value="value" v-on:input="$emit('input', $event.target.value)">
        <option value="BRL">R$</option>
        <option value="USD">US$</option>
        <option value="EUR">€</option>
        <option value="GBP">£</option>
        <option value="JPY">¥</option>
        <option value="BTC">BTC</option>
        <option value="CHF">CHF</option>
        <option value="AUD">AUD</option>
        <option value="CAD">CAD</option>
        <option value="ARS">ARS</option>
        <option value="UYU">UYU</option>
    </select>
</template>

<script>
export default {
    name: "SelectMonetaryUnit",
    props: ['name', 'value']
}
</script>

<style scoped>

</style>