<template>
    <div>
        <MenuBar/>
        <div class="contentOnNavBar">
            <SideMenu/>
            <div style="padding: 0.5rem; width: 100%">
                <div class="pageTitle" v-if="product.id !== 0">Atualizar Produto</div>
                <div class="pageTitle" v-if="product.id === 0">Cadastrar Produto</div>
                <div class="pageTitleDetails">Insira abaixo as informações do Produto. Campos marcados com "*" são obrigatórios.</div>

                <form class="row pt-2" style="margin-left: 0; margin-right: 0; margin-top: 1rem" @submit.prevent="saveProduct">

                    <div class="col-sm-12 col-lg-4 col-xl-4 mb-1">
                        <label for="name">Nome do Produto*</label>
                        <input v-model="product.name" type="text" id="name" required class="form-control form-control-sm"/>
                    </div>

                    <div class="col-sm-12 col-lg-4 col-xl-4 mb-1">
                        <label for="internalCode" class="customLabel">Código Interno *</label>
                        <div class="input-group">
                            <input v-model="product.internalCode" :disabled="product.id !== 0" type="text" id="internalCode" placeholder="Ex.: ABC1234"
                                   required class="form-control form-control-sm" aria-describedby="button-addon2"/>
                            <button v-on:click="generateInternalCode" :disabled="product.id !== 0" class="btn btn-sm btn-outline-secondary" type="button" id="button-addon2">
                                <img src="../../../assets/img/refresh_black.svg" height="16"/>
                            </button>
                        </div>
                    </div>

                    <div class="col-sm-12 col-lg-4 col-xl-4 mb-1">
                        <label for="barCode" class="customLabel">Código de Barras</label>
                        <input v-model="product.barCode" type="text" id="barCode" class="form-control form-control-sm"/>
                    </div>

                    <div class="col-12">
                        <label for="description" class="customLabel">Descrição</label>
                        <textarea rows="5" v-model="product.description" type="text" id="description" class="form-control form-control-sm"/>
                    </div>

                    <div class="col-sm-6 col-lg-2 col-xl-1 mb-1 pt-2" style="margin: 0 auto">
                        <input v-model="product.active" class="form-check-input" type="checkbox" id="active" checked>&nbsp;
                        <label class="form-check-label" for="active"> Ativo </label>
                    </div>

                    <div>
                        <div class="pageSubTitle">Grupos do Produto</div>
                    </div>

                    <div style="font-size: 1rem; margin-top: 1rem; margin-bottom: 1rem; ">
                        <NewProductGroup v-on:group-created="addCreatedGroupToGroups" class="ms-3"/>
                    </div>

                    <div class="col-12 ">
                        <div class="p-2 mb-3" style="background-color: white; border-radius: 0.3rem;">
                            Disponíveis: (clique para adicionar)<br/>
                            <div v-for="group in productGroups" :key="group.id" style="display: inline">
                                <span class="badge m-1" :style="getProductGroupColor(group)" v-on:click="addProductGroupToSelection(group)">{{ group.name }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 ">
                        <div class="p-2 mb-3" style="background-color: white; border-radius: 0.3rem;">
                            Selecionados: (clique para remover)<br/>
                            <div v-for="group in product.productGroups" :key="group.id" style="display: inline">
                                <span class="badge m-1" :style="getProductGroupColor(group)" v-on:click="removeProductGroupFromSelection(group)">{{ group.name }}</span>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div class="pageSubTitle">Detalhes do Produto</div>
                    </div>

                    <div class="col-sm-6 col-lg-4 col-xl-3 mb-1">
                        <label for="ncm" class="customLabel">NCM</label>
                        <input v-model="product.details.ncm" type="text" id="ncm" class="form-control form-control-sm"/>
                    </div>

                    <div class="col-sm-6 col-lg-4 col-xl-3 mb-1">
                        <label for="cest" class="customLabel">CEST</label>
                        <input v-model="product.details.cest" type="text" id="cest" class="form-control form-control-sm"/>
                    </div>

                    <div class="col-sm-6 col-lg-4 col-xl-3 mb-1">
                        <label for="weight" class="customLabel">Peso</label>
                        <div class="input-group mb-3">
                            <input v-model="product.details.weight" type="text" id="weight" class="form-control form-control-sm"/>
                            <SelectMassUnit name="weightMassUnit" v-model="product.details.weightUnit"/>
                        </div>

                    </div>

                    <div class="col-sm-6 col-lg-4 col-xl-3 mb-1">
                        <label for="width" class="customLabel">Largura</label>
                        <div class="input-group mb-3">
                            <input v-model="product.details.width" type="text" id="width" class="form-control form-control-sm"/>
                            <SelectLengthUnit name="widthLengthUnit" v-model="product.details.widthUnit"/>
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-4 col-xl-3 mb-1">
                        <label for="height" class="customLabel">Altura</label>
                        <div class="input-group mb-3">
                            <input v-model="product.details.height" type="text" id="height" class="form-control form-control-sm"/>
                            <SelectLengthUnit name="widthLengthUnit" v-model="product.details.heightUnit"/>
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-4 col-xl-3 mb-1">
                        <label for="length" class="customLabel">Comprimento</label>
                        <div class="input-group mb-3">
                            <input v-model="product.details.length" type="text" id="length" class="form-control form-control-sm"/>
                            <SelectLengthUnit name="widthLengthUnit" v-model="product.details.lengthUnit"/>
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-4 col-xl-3 mb-1">
                        <label for="commission" class="customLabel">Comissão</label>
                        <input type="text" id="commission" v-model="product.details.comission" class="form-control form-control-sm"/>
                    </div>


                    <div>
                        <div class="pageSubTitle mb-3">Imagens (até 5) - Tamanho (Máx. 6mb por imagem)</div>
                    </div>


                    <div style="margin: 0 auto; text-align: center;" v-if="product.images.length > 0 || filesImgs.length > 0">
                        <label class="customLabel">Imagens do Produto:</label><br/>
                        <div v-for="(img, index) in product.images" :key="index" style="display: inline-block">
                            <div class="img-wrap">
                                <span class="close" @click="removeImage(img)">X</span>
                                <img :src="$API_ADDRESS + '/download/' + img" alt="Imagem do Produto" style="height: 120px; width: auto !important; margin: 0 auto; padding: 0.4rem">
                            </div>
                        </div>
                        <div v-for="(img, index) in filesImgs" :key="index" style="display: inline-block">
                            <div class="img-wrap">
                                <span class="close" @click="removeNewImage(img)">X</span>
                                <img :src="img" alt="Imagem do Produto" style="height: 120px; width: auto !important; margin: 0 auto; padding: 0.4rem">
                            </div>
                        </div>
                    </div>

                    <div class="input-group mb-3 mt-3 position-relative d-flex justify-content-center">
                        <input type="file" class="form-control flex-column position-absolute overflow-scroll w-100" id="files" style="z-index: 2;height: 100%; opacity: 0" multiple max="5" data-max-size="2048" @change="onChangeFileUpload($event)" accept="image/*">
                        <div id="descriptionUpload" class="text-center d-block w-100 pe-4 ps-4 pt-2 pb-2">
                            <i class="bi-upload text-black justify-content-center mb-4" id="warningDownload" style="font-size: 7em; display: flex"></i>
                            <span class="text-primary fw-bold fs-4">Clique ou arraste e solte imagens para upload</span>
                        </div>
                    </div>

                    <div>
                        <div class="pageSubTitle mb-3">Valores e Custos</div>
                    </div>

                    <div class="col-sm-6 col-lg-3 col-xl-2 mb-1">
                        <label for="costPrice" class="customLabel">Preço de Custo</label>
                        <div class="input-group mb-3">
                            <SelectMonetaryUnit name="costPriceUnit" v-model="product.costPriceUnit"/>
                            <input v-model="product.costPrice" type="text" id="costPrice" class="form-control form-control-sm"/>
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-3 col-xl-2 mb-1">
                        <label for="otherCosts" class="customLabel">Outros Custos</label>
                        <div class="input-group mb-3">
                            <SelectMonetaryUnit name="otherCostsPriceUnit" v-model="product.otherCostsUnit"/>
                            <input v-model="product.otherCosts" type="text" id="otherCosts" class="form-control form-control-sm"/>
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-3 col-xl-2 mb-1">
                        <label for="finalCost" class="customLabel">Custo Final</label>
                        <div class="input-group mb-3">
                            <SelectMonetaryUnit name="finalCostPriceUnit" v-model="product.finalCostUnit"/>
                            <input v-model="product.finalCost" type="text" id="finalCost" class="form-control form-control-sm"/>
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-3 col-xl-2 mb-1">
                        <label for="margin" class="customLabel">Margem</label>
                        <input v-model="product.margin" type="text" id="margin" class="form-control form-control-sm"/>
                    </div>

                    <div class="col-sm-6 col-lg-3 col-xl-2 mb-1">
                        <label for="sellPrice" class="customLabel">Preço de Venda*</label>
                        <div class="input-group mb-2">
                            <SelectMonetaryUnit name="sellPricePriceUnit" v-model="product.sellPriceUnit"/>
                            <input v-model="product.sellPrice" type="text" id="sellPrice" class="form-control form-control-sm"/>
                        </div>
                    </div>

                    <div>
                        <div class="pageSubTitle mb-3">Variações</div>
                    </div>
                    <div style="font-size: 1rem; margin-bottom: 0.5rem;">
                        <button class="btn btn-primary btn-sm ms-3" @click="newProductVariation">Nova Variação</button>
                    </div>

                    <div id="variationsDiv">
                        <div v-for="(variation, index) in product.productVariations" :key="index" class="col-12 row">
                            <div class="col-sm-6 col-lg-4 col-xl-3 mb-1" style="height: 100%">
                                <label class="customLabel">Nome</label>
                                <div class="input-group mb-3">
                                    <input type="text" placeholder="Ex.: cor, tamanho, voltagem..." v-model="variation.name" class="form-control form-control-sm"/>
                                </div>
                            </div>
                            <div class="col-sm-6 col-lg-8 col-xl-9 mb-1">
                                <label class="customLabel">Possíveis Valores</label><br>
                                <div class="input-group mb-1">
                                    <input type="text" placeholder="Ex.: 'azul, amarelo', '40, 41, 42', '110v, 220v'..." v-model="variation.tempValue"
                                           class="form-control form-control-sm" v-on:keyup.enter="tempVariationValueToValue(index)">
                                </div>
                                <div>
                                    <span v-for="(val, valIndex) in variation.possibleValues" :key="valIndex" class="badge rounded-pill bg-primary pl-3" style="margin-left: 0.3rem;">
                                        <span @click="removeVariationValue(variation, val)" style="cursor: pointer" title="remover">
                                            {{ val }}
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style="font-size: 1rem; margin-top: 1rem; margin-bottom: 0.5rem; display: none;">
                        <span style="border-bottom: 2px solid #3aa0c3; color: #3aa0c3;">Fornecedores</span>
                        <button class="btn btn-success btn-sm ms-3">Novo</button>
                    </div>

                    <div>
                        <div class="pageSubTitle mb-3">Observações</div>
                    </div>

                    <div class="col-sm-12 col-lg-6 mb-2">
                        <label for="observations" class="customLabel">Observações</label>
                        <textarea type="text" id="observations" class="form-control form-control-sm" rows="5" v-model="product.observations"/>
                    </div>

                    <div class="col-sm-12 col-lg-6 mb-2">
                        <label for="internalObservations" class="customLabel">Observações Internas</label>
                        <textarea type="text" id="internalObservations" class="form-control form-control-sm" rows="5" v-model="product.internalObservations"/>
                    </div>

                    <div style="width: 100%; text-align: center">
                        <input type="submit" class="btn btn-success" :value="product.id === 0 ? 'Cadastrar Produto' : 'Atualizar Produto'"/>
                    </div>
                </form>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
import MenuBar from '../../template/MenuBar'
import SideMenu from '../../template/SideMenu'
import Footer from '../../template/Footer'
import SelectMonetaryUnit from "../components/SelectMonetaryUnit";
import SelectLengthUnit from "../components/SelectLengthUnit";
import SelectMassUnit from "../components/SelectMassUnit";
import NewProductGroup from "../components/NewProductGroup";
import axios from "axios";

export default {
    components: {
        MenuBar,
        SideMenu,
        Footer,
        SelectMonetaryUnit,
        SelectMassUnit,
        SelectLengthUnit,
        NewProductGroup,
    },

    data: function () {
        return {
            product: {
                id: 0,
                name: null,
                internalCode: null,
                description: null,
                barCode: null,
                active: true,
                inventoryManaged: false,
                invoiceManaged: false,
                productGroups: [],
                details: {
                    weight: null,
                    weightUnit: 'KILOGRAMS',
                    width: null,
                    widthUnit: 'CENTIMETERS',
                    height: null,
                    heightUnit: 'CENTIMETERS',
                    length: null,
                    lengthUnit: 'CENTIMETERS',
                    comission: null,
                    ncm: null,
                    cest: null,
                },
                images: [],
                costPrice: null,
                costPriceUnit: 'BRL',
                otherCosts: null,
                otherCostsUnit: 'BRL',
                finalCost: null,
                finalCostUnit: 'BRL',
                margin: null,
                sellPrice: null,
                sellPriceUnit: 'BRL',
                productVariations: [],
                observations: null,
                internalObservations: null
            },
            filesImgs: [],
            productGroups: [],
            files: [],
        }
    },

    mounted() {
        if (axios.defaults.headers.common["Authorization"] == undefined || axios.defaults.headers.common["Authorization"] == '') {
            this.$router.push({name: 'Index'})
        }

        if (this.$route.query.id !== undefined) {
            this.getProduct();
        }
        this.getProductGroups();
    },


    methods: {
        removeVariationValue(variation, value) {
            variation.possibleValues.forEach(v => {
                if (v === value) {
                    variation.possibleValues.splice(variation.possibleValues.indexOf(v), 1)
                }
            })
        },

        generateInternalCode() {
            axios.get(this.$API_ADDRESS + '/product/internalCode')
                .then(response => {
                    this.product.internalCode = response.data;
                })
        },

        getProductGroupColor(product) {
            return 'cursor: pointer; background-color: ' + product.color + '; color: ' + this.pickTextColorFromBg(product.color);
        },

        addCreatedGroupToGroups(createdGroup) {
            this.productGroups.push(createdGroup)
        },

        tempVariationValueToValue(index) {
            this.product.productVariations[index].possibleValues.push(this.product.productVariations[index].tempValue)
            this.product.productVariations[index].tempValue = ''
        },

        getProductGroups() {
            axios.get(this.$API_ADDRESS + '/productGroup')
                .then(response => {
                    this.productGroups = response.data;
                })
        },

        async getProduct() {
            await axios.get(this.$API_ADDRESS + '/product/' + this.$route.query.id)
                .then(response => {
                    this.product = response.data;
                })
        },

        newProductVariation(e) {
            if (e.detail === 1) {
                this.product.productVariations.push({name: null, possibleValues: [], tempValue: null});
                console.log(e)
            }
            e.preventDefault();
        },

        addProductGroupToSelection(product) {
            if (this.product.productGroups.indexOf(product) === -1) {
                this.product.productGroups.push(product);
            }
        },

        removeProductGroupFromSelection(product) {
            this.product.productGroups.splice(this.product.productGroups.indexOf(product), 1);
        },

        onChangeFileUpload(e) {
            if (e.target.files.length < 6) {
                e.target.files.forEach(f => {
                    if (f.size > 6290000) {
                        this.showMessage('Tamanho Máximo: 6MB/imagem', 'WARN');
                        return;
                    }
                })
                this.product.images = []
                this.files = e.target.files;
                this.filesImgs = []
                this.files.forEach(f => {
                    if ((this.filesImgs.length + this.product.images.length) < 5) {
                        this.filesImgs.push(URL.createObjectURL(f));
                    }
                })
            } else {
                this.showMessage('Máximo de 5 imagens', 'WARN');
            }
        },

        saveProduct() {
            if (this.product.sellPrice !== null && this.product.sellPrice > 0) {
                this.verifierSentence()
                if (this.product.id === 0) {
                    this.product.productVariations.forEach(variation => {
                        delete variation.tempValue;
                    })

                    let formDataFile = new FormData();
                    this.files.forEach(file => {
                        formDataFile.append("files", file);
                    })
                    axios.post(this.$API_ADDRESS + '/product', this.product)
                        .then(response => {
                            if (this.filesImgs.length > 0) {
                                axios.put(this.$API_ADDRESS + `/product/updateImages?productId=${response.data}`, formDataFile)
                                    .then(() => {
                                        this.$router.push({name: 'ProductView', query: {id: response.data}})
                                    })
                                return;
                            }
                            this.$router.push({name: 'ProductView', query: {id: response.data}})
                        })
                } else {
                    this.updateProduct()
                }
            } else {
                this.showMessage("Preço de venda inválido.", "ERROR")
            }
        },

        pickTextColorFromBg(bgColor) {
            let color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
            let r = parseInt(color.substring(0, 2), 16); // hexToR
            let g = parseInt(color.substring(2, 4), 16); // hexToG
            let b = parseInt(color.substring(4, 6), 16); // hexToB
            let totCol = ((r * 0.299) + (g * 0.587) + (b * 0.114));
            console.log('R: ' + r + ' G: ' + g + ' B: ' + b + ' Total: ' + totCol);
            return (totCol > 186) ? '#000' : '#fff';
        },

        updateProduct() {
            this.product.productVariations.forEach(variation => {
                delete variation.tempValue;
            })

            let formDataFile = new FormData();
            this.files.forEach(file => {
                formDataFile.append("files", file);
            })

            this.product.images.forEach(fileItem => {
                formDataFile.append("files", fileItem);
            })

            axios.put(this.$API_ADDRESS + '/product', this.product)
                .then(() => {
                    if (this.filesImgs.length > 0 || this.product.images.length > 0) {
                        axios.put(this.$API_ADDRESS + `/product/updateImages?productId=${this.product.id}`, formDataFile)
                            .then(() => {
                                this.$router.push({name: 'ProductView', query: {id: this.product.id}})
                            })
                        return;
                    }
                    this.$router.push({name: 'ProductView', query: {id: this.product.id}})
                })
        },

        removeImage(img) {
            this.product.images.splice(this.product.images.indexOf(img), 1);
        },

        removeNewImage(img) {
            this.filesImgs.splice(this.filesImgs.indexOf(img), 1);
        },

        transformImg: (img) => {
            return URL.createObjectURL(img)
        },

        sentenceCorrection: function (item) {
            if (item !== null && item !== '') {
                let convertItem = item.toString().replace(',', '.')
                if (convertItem.length !== 0) {
                    if (convertItem.indexOf(',') === -1 && convertItem.indexOf('.') === -1) {
                        convertItem += '.00'
                    } else if (convertItem.endsWith(',') || convertItem.endsWith('.')) {
                        convertItem += '00'
                    }
                }
                return convertItem
            }
        },

        verifierSentence: function () {
            this.product.sellPrice = this.sentenceCorrection(this.product.sellPrice)
            this.product.finalCost = this.sentenceCorrection(this.product.finalCost)
            this.product.otherCosts = this.sentenceCorrection(this.product.otherCosts)
            this.product.costPrice = this.sentenceCorrection(this.product.costPrice)
        },

    },
}


</script>

<style scoped>
.img-wrap {
    position: relative;
}

.img-wrap .close {
    position: absolute;
    top: 2px;
    right: 2px;
    z-index: 100;
}

.close {
    margin: 0.2rem 0.3rem 0.2rem 0.4rem;
    padding: 0 0.4rem 0 0.4rem;
    background-color: #c44133;
    color: white;
    cursor: pointer;
    font-weight: bold;
    border-radius: 1rem;
}
</style>