<template>
    <div>
        <MenuBar/>
        <div class="contentOnNavBar">
            <SideMenu/>
            <div style="padding: 0.5rem; width: 100%; overflow: hidden">
                <div class="pageTitle">Dashboard</div>
                <div class="pageTitleDetails">Abaixo você encontra gráficos de desempenho dos últimos 7 dias.</div>

                <div class="row">
                    <div class="chartDiv">
                        <canvas id="sellsChart" width="100"></canvas>
                        <b>Número de Vendas</b>
                    </div>

                    <div class="chartDiv">
                        <canvas id="sellsValueChart" width="100"></canvas>
                        <b>Valor Diário das Vendas</b>
                    </div>

                    <div class="chartDiv">
                        <canvas id="catalogsChart" width="100"></canvas>
                        <b>Visualizações de Catálogo</b>
                    </div>


                </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
import MenuBar from '../../template/MenuBar'
import SideMenu from '../../template/SideMenu'
import Footer from '../../template/Footer'
import Chart from 'chart.js'
import axios from "axios";

export default {
    components: {
        MenuBar,
        SideMenu,
        Footer,
    },

    data: function () {
        return {
            sellsChart: {
                labels: [],
                datasets: [
                    {
                        label: 'Vendas neste dia',
                        backgroundColor: 'rgb(73,198,4)',
                        borderColor: 'rgb(0,0,0)',
                        borderWidth: 1,
                        data: []
                    }
                ],
            },

            sellsValueChart: {
                labels: [],
                datasets: [
                    {
                        label: 'Valor em vendas por dia (R$)',
                        backgroundColor: 'rgb(32,119,236)',
                        borderColor: 'rgb(0,0,0)',
                        data: []
                    }
                ],
            },

            catalogsChart: {
                labels: [],
                datasets: [
                    {
                        label: 'Visualizações de catálogos',
                        backgroundColor: 'rgb(201,2,37)',
                        borderColor: 'rgb(0,0,0)',
                        borderWidth: 1,
                        data: []
                    }
                ],
            },
            chartInterval: 7
        }
    },

    mounted() {
        if (axios.defaults.headers.common["Authorization"] == undefined || axios.defaults.headers.common["Authorization"] == '') {
            this.$router.push({name: 'Index'})
        }
        this.getData()
    },

    methods: {
        getData() {
            axios.get(this.$API_ADDRESS + '/charts/' + this.chartInterval)
                .then(response => {
                    response.data.orders.forEach(order => {
                        let tempDate = new Date(order.initialDate)
                        let initDate = tempDate.getFullYear() + '-' + tempDate.getMonth() + '-' + tempDate.getDay()
                        if (this.sellsChart.labels.indexOf(initDate) < 0) {
                            this.sellsChart.labels.push(initDate)
                            this.sellsValueChart.labels.push(initDate)
                            this.sellsChart.datasets[0].data.push(1)
                            this.sellsValueChart.datasets[0].data.push(order.total)
                        } else {
                            this.sellsChart.datasets[0].data[this.sellsChart.labels.indexOf(initDate)]++
                            this.sellsValueChart.datasets[0].data[this.sellsChart.labels.indexOf(initDate)] += order.total
                        }
                    })

                    const data2 = this.sellsChart
                    new Chart(
                        document.getElementById('sellsChart'),
                        {type: 'line', data: data2, options: {}}
                    );

                    const data3 = this.sellsValueChart
                    new Chart(
                        document.getElementById('sellsValueChart'),
                        {type: 'bar', data: data3, options: {}}
                    );

                    response.data.catalogs.forEach(catalog => {
                        let tempDate = new Date(catalog.date)
                        let initDate = tempDate.getFullYear() + '-' + tempDate.getMonth() + '-' + tempDate.getDay()
                        if (this.catalogsChart.labels.indexOf(initDate) < 0) {
                            this.catalogsChart.labels.push(initDate)
                            this.catalogsChart.datasets[0].data.push(1)
                        } else {
                            this.catalogsChart.datasets[0].data[this.catalogsChart.labels.indexOf(initDate)]++
                        }
                    })

                    const data4 = this.catalogsChart
                    console.log(this.catalogsChart)
                    new Chart(
                        document.getElementById('catalogsChart'),
                        {type: 'line', data: data4, options: {}}
                    );
                })
                .catch(e => {
                    this.showMessage(e.data, 'ERROR');
                })
        }
    }
}
</script>

<style scoped>

@media screen and (max-width: 992px) {
    .chartDiv {
        width: 90%;
    }
}

@media screen and (min-width: 992px) {
    .chartDiv {
        width: 43%;
    }
}

.chartDiv {
    text-align: center;
    margin: 0 auto;
    height: fit-content;
    margin-top: 1rem;
}
</style>