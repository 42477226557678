<template>
    <div>
        <MenuBar/>
        <div class="contentOnNavBar">
            <SideMenu/>
            <div style="padding: 0.5rem; width: 100%">
                <div class="pageTitle" style="">Lista de Clientes</div>

                <div style="padding: 1rem 0 0.5em 0; width: 100%;">
                    <button class="btn btn-success btn-sm" @click="$router.push({name: 'ClientCreate'})">Novo Cliente</button>
                    <button class="btn btn-danger btn-sm" style="float: right;" :disabled="selectedClients.length == 0">Excluir Selecionados</button>
                </div>

                <div id="no-more-tables">
                    <table class="table table-sm" style="padding-top: 0.5rem;" v-if="clients">
                        <thead>
                        <tr>
                            <th scope="col">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox">
                                </div>
                            </th>
                            <th scope="col">Nome
                                <i class="fas fa-sort" v-if="!$refs.pagination.sortFieldsContainsField('name', null)" @click="$refs.pagination.addSortField('name')"></i>
                                <i class="fas fa-sort-up" v-if="$refs.pagination.sortFieldsContainsField('name', 'ASC')" @click="$refs.pagination.addSortField('name')"></i>
                                <i class="fas fa-sort-down" v-if="$refs.pagination.sortFieldsContainsField('name', 'DESC')" @click="$refs.pagination.addSortField('name')"></i>
                            </th>
                            <th scope="col">Email
                                <i class="fas fa-sort" v-if="!$refs.pagination.sortFieldsContainsField('email', null)" @click="$refs.pagination.addSortField('email')"></i>
                                <i class="fas fa-sort-up" v-if="$refs.pagination.sortFieldsContainsField('email', 'ASC')" @click="$refs.pagination.addSortField('email')"></i>
                                <i class="fas fa-sort-down" v-if="$refs.pagination.sortFieldsContainsField('email', 'DESC')" @click="$refs.pagination.addSortField('email')"></i>
                            </th>
                            <th scope="col">Celular</th>
                            <th scope="col" style="width: 5.7rem">Ações</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(client, index) in clients" :key="index">
                            <td data-title="Selecionar" class="align-middle">
                                <div class="form-check">
                                    <input class="form-check-input" :value="client.id" type="checkbox" v-model="selectedClients">
                                </div>
                            </td>
                            <td data-title="Nome" class="align-middle">{{ client.name }}</td>
                            <td data-title="Email" class="align-middle">{{ client.email }}</td>
                            <td data-title="Celular" class="align-middle">{{ client.privatePhone }}</td>
                            <td class="align-middle">
                                <div class="btn-group " role="group">
                                    <button id="btnGroupDrop1" type="button" class="btn btn-warning btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                        Ações
                                    </button>
                                    <ul class="dropdown-menu" aria-labelledby="btnGroupDrop1" style="min-width: unset !important; padding: 0 !important">
                                        <li><a class="dropdown-item sm" @click="$router.push({name: 'ClientView', query: {'id': client.id}})" style="cursor: pointer">
                                            <i class="fas fa-search fa-sm" style="margin-right: 0.5rem"></i>Ver</a>
                                        </li>
                                        <li><a class="dropdown-item sm" @click="$router.push({name: 'ClientCreate', query: {'id': client.id}})" style="cursor: pointer">
                                            <i class="fas fa-pen fa-sm" style="margin-right: 0.5rem"></i>Editar</a>
                                        </li>
                                        <li><a class="dropdown-item sm" @click="$router.push({name: 'ClientOrderCreate', query: {'clientId': client.id}})" style="cursor: pointer">
                                            <i class="fas fa-plus-circle fa-sm" style="margin-right: 0.5rem"></i>Criar Pedido</a>
                                        </li>
                                        <li><a class="dropdown-item sm" @click="selectedClients = []; selectedClients.push(client.id)" data-bs-toggle="modal" data-bs-target="#confirmDelete" style="cursor: pointer">
                                            <i class="fas fa-trash fa-sm" style="margin-right: 0.5rem"></i>Excluir</a></li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <Pagination ref="pagination" v-on:page-clicked="(c) => this.clients = c" endpoint-list="/client/list/" endpoint-count="/client/count"/>
                <ConfirmDelete v-on:done="$refs.pagination.getObjects(-1)" :objects-to-exclude="selectedClients"
                               :message="'Tem certeza que deseja excluir o cliente selecionado?'" :endpoint="'/client'"/>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
import MenuBar from '../../template/MenuBar'
import SideMenu from '../../template/SideMenu'
import Footer from '../../template/Footer'
import Pagination from '@/components/pages/components/Pagination.vue'
import ConfirmDelete from "@/components/pages/components/ConfirmDelete";
import axios from "axios";

export default {
    components: {
        ConfirmDelete,
        MenuBar,
        SideMenu,
        Footer,
        Pagination
    },

    data: function () {
        return {
            clients: null,
            selectedClients: []
        }
    },

    mounted() {
        if (axios.defaults.headers.common["Authorization"] == undefined || axios.defaults.headers.common["Authorization"] == '') {
            this.$router.push({name: 'Index'})
        }
    },
}
</script>

<style scoped>
</style>