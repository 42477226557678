<template>
    <div class="bg-primary" style="font-family: sourcesanspro">
        <div class="vCenterWrapper menuBackground" id="headerWrapper">
            <div style="width: 100%; font-size: 0.85rem;" v-if="clientOrder">
                <div style="float: left; text-align: center; margin-left: 1rem; cursor: pointer" @click="$router.push({name: 'CompanyDetails'})" v-if="clientOrder.company">
                    <img :src="$API_ADDRESS + '/download/' + clientOrder.company.image" style="height: 3.7rem" >
                </div>
                <div style="float: right; text-align: center; margin-right: 1rem; cursor: pointer" @click="$router.push({name: 'CheckoutOrder'})">
                    <img src="../../assets/img/request.png" width="35"><br/>
                    Pedido ({{ clientOrder.products.length }} Itens)
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    data: function () {
        return {
            catalog: null,
            clientOrder: null,
        }
    },

    methods: {
        getCatalog: async function (catalogId) {
            if (catalogId !== null && (this.catalog === null || this.catalog.id !== catalogId)) {
                this.catalog = await this.getCatalogFromAPI(catalogId)
            } else if (sessionStorage.getItem('catalog') !== null) {
                this.catalog = JSON.parse(atob(sessionStorage.catalog))
            }
            return this.catalog
        },

        getClientOrder: function () {
            if (sessionStorage.getItem('clientOrder') === null) {
                return this.createNewOrderOnSessionStorage();
            } else {
                this.clientOrder = JSON.parse(atob(sessionStorage.clientOrder))
                this.getClientOrderTotal()
            }
            return this.clientOrder
        },

        getSelectedProduct: function (catalogProductId) {
            let prod = null
            this.catalog.catalogProducts.forEach(catalogProduct => {
                if (catalogProduct.id == catalogProductId) {
                    prod = catalogProduct;
                }
            })
            return prod
        },

        createNewOrderOnSessionStorage: function () {
            let clientOrder = {
                client: {
                    name: null,
                    email: null,
                    privatePhone: null,
                    license: this.catalog.license,
                },
                products: [],
                deliverCosts: null,
                otherCosts: null,
                discount: null,
                total: null,
                observations: null,
                paymentQuotas: [],
                paymentForm: 0,
                company: this.catalog.license
            }
            sessionStorage.setItem('clientOrder', btoa(JSON.stringify(clientOrder)))
            this.clientOrder = clientOrder;
            return clientOrder;
        },

        getCatalogFromAPI: async function (catalogId) {
            let response = await axios.get(this.$API_ADDRESS + '/catalog/watch/' + catalogId)
            this.catalog = response.data;
            this.catalog.catalogProducts.forEach(catalogProduct => {
                catalogProduct.quantity = 1
            })
            sessionStorage.setItem('catalog', btoa(JSON.stringify(this.catalog)))
            return this.catalog;
        },

        addProductToOrder: function (catalogProduct) {
            if (!this.orderHasProduct(catalogProduct.id)) {
                this.clientOrder.products.push(catalogProduct)
                this.getClientOrderTotal()
                this.saveOrderToSessionStorage()
            }
            return this.clientOrder
        },

        removeProductFromOrder: function (catalogProduct) {
            if (this.orderHasProduct(catalogProduct.id)) {
                this.clientOrder.products.forEach(p => {
                    if (p.id == catalogProduct.id)
                        this.clientOrder.products.splice(this.clientOrder.products.indexOf(p), 1)
                })
                this.saveOrderToSessionStorage()
            }
            return this.clientOrder
        },

        getClientOrderTotal: function () {
            let total = 0.0;
            this.clientOrder.products.forEach(p => {
                total += p.price * p.quantity
            })
            this.clientOrder.total = total
        },

        saveOrderToSessionStorage: function () {
            sessionStorage.setItem('clientOrder', btoa(JSON.stringify(this.clientOrder)))
        },

        orderHasProduct: function (catalogProductId) {
            let result = false
            this.clientOrder.products.forEach(p => {
                if (p.id == catalogProductId)
                    result = true
            })
            return result
        }
    },
};
</script>

<style scoped>
#headerWrapper {
    height: 5rem;
    justify-content: center;
}

</style>