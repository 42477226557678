<template>
    <div>
        <MenuBar/>
        <div class="contentOnNavBar">
            <SideMenu/>
            <div style="padding: 0.5rem; width: 100%">
                <div class="pageTitle">Visualizar Pedido</div>
                <div class="pageTitleDetails">Abaixo as informações do Pedido.</div>

                <div v-if="clientOrder" style="padding: 0.5rem;">
                    <div class="pageSubTitle mb-2">Dados do Cliente</div>

                    <span class="label">Cliente:</span> {{ clientOrder.client.name }}<br/>
                    <span class="label">Email:</span> {{ clientOrder.client.email }}<br/>
                    <span class="label">Telefone:</span> {{ clientOrder.client.privatePhone }}<br/>

                    <div class="pageSubTitle mb-2">Dados do Pedido</div>
                    <span class="label">Data do Pedido:</span> {{ clientOrder.initialDate | moment("DD/MM/YYYY HH:mm") }}<br/>
                    <span class="label">Data de Fechamento:</span> {{ clientOrder.finalDate | moment("DD/MM/YYYY HH:mm") }}<br/>
                    <span class="label">Status do Pedido:</span> {{ orderStatusPt(clientOrder.status) }}<br/>
                    <span class="label">Observações:</span> {{ clientOrder.observations }}<br/>
                    <span class="label">Observações Internas:</span> {{ clientOrder.internalObservations }}<br/>
                    <span class="label">Canal de Venda:</span> {{ clientOrder.sellChannel }}<br/>


                    <div class="pageSubTitle mb-2">Produtos</div>
                    <table class="table table-sm" style="margin-top: 1rem;">
                        <thead>
                        <tr>
                            <th scope="col">Produto</th>
                            <th scope="col">Quantidade</th>
                            <th scope="col">Preço Unitário</th>
                            <th scope="col">Subtotal</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(product, index) in clientOrder.products" :key="index">
                            <td class="align-middle">{{ product.product.name }}</td>
                            <td class="align-middle">{{ product.quantity }}</td>
                            <td class="align-middle">R$: {{ product.value.toLocaleString() }}</td>
                            <td class="align-middle">R$: {{ product.subTotal.toLocaleString() }}</td>
                        </tr>
                        <tr>
                            <td align="center" colspan="4"><span class="label">Total:</span> R$: {{ getTotal().toLocaleString() }}</td>
                        </tr>
                        </tbody>
                    </table>

                    <div class="pageSubTitle mb-2">Serviços</div>

                    <div class="pageSubTitle mb-2">Dados de Financeiros</div>
                    <span class="label">Forma de Pagamento:</span> {{ clientOrder.paymentForm }}<br/>
                    <span class="label">Desconto:</span> R$: {{ clientOrder.discount.toLocaleString() }}<br/>
                    <span class="label">Frete:</span> R$: {{ clientOrder.deliverCost.toLocaleString() }}<br/>
                    <span class="label">Total final:</span> R$: {{ (getTotal() - clientOrder.discount).toLocaleString() }}<br/>

                    <div style="margin: 0 auto; width: 100%; text-align: center">
                        <div class="btn-group " role="group">
                            <button id="btnGroupDrop1" type="button" class="btn btn-primary btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                Ações
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="btnGroupDrop1" style="min-width: unset !important; padding: 0 !important">
                                <li><a class="dropdown-item" @click="$router.push({name: 'ClientOrderCreate', query: {'id': clientOrder.id}})" style="cursor: pointer"><i class="fas fa-pen" style="margin-right: 0.5rem"></i>Editar</a></li>
                                <li><a class="dropdown-item" style="cursor: pointer"><i class="fas fa-trash" style="margin-right: 0.5rem"></i>Excluir</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
import MenuBar from '../../template/MenuBar'
import SideMenu from '../../template/SideMenu'
import Footer from '../../template/Footer'
import axios from "axios";

export default {
    components: {
        MenuBar,
        SideMenu,
        Footer,
    },

    created() {
        if (this.$route.query.id !== '') {
            axios.get(this.$API_ADDRESS + '/clientOrder/' + this.$route.query.id, null)
                .then(response => {
                    this.clientOrder = response.data;
                    if (this.clientOrder.status === 'RECEIVED') {
                        this.setSeen(this.clientOrder.id)
                    }
                })
        }
    },

    data: function () {
        return {
            clientOrder: null
        }
    },

    mounted() {
        if (axios.defaults.headers.common["Authorization"] == undefined || axios.defaults.headers.common["Authorization"] == '') {
            this.$router.push({name: 'Index'})
        }
    },

    methods: {
        setSeen(clientOrderId) {
            if (this.clientOrder.status === 'RECEIVED') {
                axios.post(this.$API_ADDRESS + '/clientOrder/seen/' + clientOrderId)
                    .then(() => {
                        this.clientOrder.status = 'SEEN';
                    })
                    .catch(e => {
                        this.showMessage(e.data, 'ERROR');
                    })
            } else {
                console.log(this.clientOrder.status)
            }
        },

        orderStatusPt(status) {
            switch (status) {
                case 'RECEIVED':
                    return 'Recebida';
                case 'SEEN':
                    return 'Visto';
                case 'PROCESSED':
                    return 'Processado';
                case 'DELIVERED':
                    return 'Entregue';
                case 'FINISHED':
                    return 'Finalizado';
            }
            return '';
        },

        getTotal(){
            let total = 0.0
            this.clientOrder.products.forEach(p => {
                total += p.subTotal
            })
            return total
        }
    }
}
</script>

<style scoped>
</style>