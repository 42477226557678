<template>
    <div style="font-family: sourcesanspro">
        <BigHeaderCatalogView ref="header"/>

        <div class="contentOnBigHeader" style="background-color: #eeeeee; padding: 1rem;" v-if="product && catalog">
            <span @click="$router.push({name: 'CatalogView', query: {id: catalog.id}})" style="cursor: pointer; font-size: 1.2rem; font-weight: bold;">
                &#60;&#60; {{ catalog.name }}
            </span>

            <div class="row" style="height: calc(100% - 4rem)">
                <div style="height: 100%; width: 50%" class="vCenterWrapper">
                    <div v-if="product.images" style="margin: 0 auto;">
                        <ImageCarousel :images-list="product.images"/>
                    </div>
                    <div v-if="!product.images" style="margin: 0 auto;">
                        <img src="../../../assets/img/product.png" height="200"/>
                    </div>
                </div>
                <div style="height: 100%; text-align: center; margin: 0 auto; width: 50%; color: #333333" class="vCenterWrapper">
                    <div style="margin: 0 auto; font-family: sourcesanspro;">
                        <h3><b>{{ product.name }}</b></h3>
                        <p style="text-align: justify">{{ product.description }}</p>
                        <h4>R$: {{ product.sellPrice }}</h4>
                        <div v-for="(variation, indexV) in product.productVariations" :key="indexV">
                            <b>{{ variation.name }}: </b>
                            <div v-for="(variationValue, index) in variation.possibleValues" :key="index" style="display: inline">
                                {{ variationValue }};
                            </div>
                        </div>
                        <button tyle="margin-top: 0.5rem" class="btn btn-primary" @click="addProductToOrder(product)" v-if="!$refs.header.orderHasProduct(product.id)">Adicionar Ao Pedido</button>
                        <button tyle="margin-top: 0.5rem" class="btn btn-danger" @click="removeProductFromOrder(product)" v-if="$refs.header.orderHasProduct(product.id)">Remover do Pedido</button>
                        <div style="margin: 1rem; text-align: center">
                            <h5>Compartilhar Este Produto</h5>
                            <a :href="'https://twitter.com/share?url=' + this.getEncodedUrl()">
                                <img src="../../../assets/img/twitter.png" class="shareBtn">
                            </a>
                            <a :href="'https://facebook.com/sharer/sharer.php?u=' + this.getEncodedUrl()">
                                <img src="../../../assets/img/facebook.png" class="shareBtn">
                            </a>
                            <a :href="'whatsapp://send?text=' + this.getEncodedUrl()">
                                <img src="../../../assets/img/whatsapp.png" class="shareBtn">
                            </a>
                            <a :href="'https://www.linkedin.com/shareArticle?mini=true&url=' + this.getEncodedUrl()">
                                <img src="../../../assets/img/linkedin.png" class="shareBtn">
                            </a>
                            <a :href="this.getEmailShare()">
                                <img src="../../../assets/img/email.png" class="shareBtn">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Footer/>
    </div>
</template>

<script>
import Footer from '../../template/Footer'
import BigHeaderCatalogView from "@/components/template/BigHeaderCatalogView";
import ImageCarousel from "@/components/pages/components/ImageCarousel";

export default {
    components: {
        ImageCarousel,
        BigHeaderCatalogView,
        Footer,
    },

    data: function () {
        return {
            product: null,
            catalog: null,
            clientOrder: null,
        }
    },

    async mounted() {
        if (this.$route.query.productId === undefined || this.$route.query.catalogId === undefined) {
            await this.$router.push({name: '404'})
            return
        }
        this.catalog = await this.$refs.header.getCatalog(this.$route.query.catalogId)
        this.clientOrder = this.$refs.header.getClientOrder()
        this.product = this.$refs.header.getSelectedProduct(this.$route.query.productId)
    },

    methods: {
        addProductToOrder: function (product) {
            this.clientOrder = this.$refs.header.addProductToOrder(product)
        },

        removeProductFromOrder: function (product) {
            this.clientOrder = this.$refs.header.removeProductFromOrder(product)
        },

        getEncodedUrl: function () {
            return encodeURIComponent('http://www.newspirit.com/catalogProductVire?productId=' + this.product.id + '&catalogId=' + this.catalog.id)
        },

        getEmailShare: function () {
            return 'mailto:?subject='+encodeURIComponent(this.product.name);
        }
    }
}
</script>

<style scoped>
#headerWrapper {
    height: 5rem;
    justify-content: center;
}

.shareBtn {
    margin: 0.2rem;
    width: 30px;
    cursor: pointer;
}
</style>