<template>
    <div>
        <MenuBar/>

        <div class="contentOnNavBar">
            <SideMenu/>
            <div style="padding: 0.5rem; width: 100%">
                <div class="pageTitle">Visualizar Cliente</div>
                <div class="pageTitleDetails">Abaixo as informações do Cliente.</div>

                <div v-if="client" style="padding: 0.5rem;">
                    <span class="label">Nome:</span> {{ client.name }}<br/>
                    <span class="label">Email:</span> {{ client.email }}<br/>
                    <span class="label">Tipo:</span> {{ personTypePt(client.personType) }}<br/>
                    <span class="label">CPF:</span> {{ client.cpf }}<br/>
                    <span class="label">CNPJ:</span> {{ client.cnpj }}<br/>
                    <span class="label">Telefone:</span> {{ client.commercialPhone }}<br/>
                    <span class="label">Celular:</span> {{ client.privatePhone }}<br/>
                    <span class="label">RG:</span> {{ client.rg }}<br/>
                    <span class="label">Site:</span> {{ client.site }}<br/>
                    <span class="label">Status:</span> {{ client.status }}<br/>

                    <div>
                        <div class="pageSubTitle mb-3">Endereços</div>
                    </div>
                    <div v-if="client.addresses">
                        <div v-for="(address, index) in client.addresses" :key="index">
                            <span class="label">Endereço: </span>{{ address.street }} - {{ address.number }}, {{ address.district }}.<br/>
                            <span class="label">Cep: </span>{{ address.postalCode }}
                            <span v-if="address.complement">, {{ address.complement }}</span><br/>
                            <span class="label">Cidade: </span>{{ address.city }} - {{ address.state }}, {{ address.country }}<br/>
                        </div>
                    </div>

                    <div>
                        <div class="pageSubTitle mb-3">Histórico de Pedidos</div>
                        <table class="table table-sm" style="padding-top: 0.5rem;" v-if="clientOrders">
                            <thead>
                            <tr>
                                <th scope="col">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" onclick="">
                                    </div>
                                </th>
                                <th scope="col">Data</th>
                                <th scope="col">Status</th>
                                <th scope="col">Produtos</th>
                                <th scope="col">Total</th>
                                <th scope="col" style="width: 5.7rem">Ações</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(clientOrder, index) in clientOrders" :key="index">
                                <th scope="row" class="align-middle">
                                    <div class="form-check">
                                        <input class="form-check-input" :value="clientOrder.id" type="checkbox" v-model="selectedOrders">
                                    </div>
                                </th>
                                <td class="align-middle">{{ clientOrder.initialDate | moment("DD/MM/YYYY HH:mm") }}</td>
                                <td class="align-middle">{{ orderStatusPt(clientOrder.status) }}</td>
                                <td class="align-middle">{{ clientOrder.products.length }}</td>
                                <td class="align-middle">R$ {{ clientOrder.total.toLocaleString() }}</td>
                                <td class="align-middle">
                                    <div class="btn-group " role="group">
                                        <button id="btnGroupDrop2" type="button" class="btn btn-warning btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                            Ações
                                        </button>
                                        <ul class="dropdown-menu" aria-labelledby="btnGroupDrop2" style="min-width: unset !important; padding: 0 !important">
                                            <li><a class="dropdown-item sm" @click="$router.push({name: 'ClientOrderView', query: {'id': clientOrder.id}})" style="cursor: pointer">
                                                <i class="fas fa-search fa-sm" style="margin-right: 0.5rem"></i>Ver</a>
                                            </li>
                                            <li><a class="dropdown-item sm" @click="$router.push({name: 'ClientOrderCreate', query: {'id': clientOrder.id}})" style="cursor: pointer"><i class="fas fa-pen fa-sm" style="margin-right: 0.5rem"></i>Editar</a></li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>


                    <div style="margin: 0 auto; width: 100%; text-align: center">
                        <div class="btn-group " role="group">
                            <button id="btnGroupDrop1" type="button" class="btn btn-primary btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                Ações
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="btnGroupDrop1" style="min-width: unset !important; padding: 0 !important">
                                <li><a class="dropdown-item sm" style="cursor: pointer" @click="$router.push({name: 'ClientCreate', query:{'id': client.id}})"><i class="fas fa-pen" style="margin-right: 0.5rem"></i>Editar</a></li>
                                <li><a class="dropdown-item sm" style="cursor: pointer" @click="$router.push({name: 'ClientOrderCreate', query:{'clientId': client.id}})"><i class="fas fa-plus-circle" style="margin-right: 0.5rem"></i>Criar Pedido</a></li>
                                <li><a class="dropdown-item sm" style="cursor: pointer"> <i class="fas fa-trash" style="margin-right: 0.5rem"></i>Excluir</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <ConfirmDelete v-on:done="$router.push({name: 'Clients'})" :objects-to-exclude="[client.id]" v-if="client"
                           :message="'Tem certeza que deseja excluir este cliente?'" :endpoint="'/client'"/>
        </div>
        <Footer/>
    </div>
</template>

<script>
import MenuBar from '../../template/MenuBar'
import SideMenu from '../../template/SideMenu'
import Footer from '../../template/Footer'
import axios from "axios";
import ConfirmDelete from "@/components/pages/components/ConfirmDelete";

export default {
    components: {
        ConfirmDelete,
        MenuBar,
        SideMenu,
        Footer,
    },

    mounted() {
        if (axios.defaults.headers.common["Authorization"] == undefined || axios.defaults.headers.common["Authorization"] == '') {
            this.$router.push({name: 'Index'})
        }
    },

    created() {
        if (this.$route.query.id !== '') {
            axios.get(this.$API_ADDRESS + '/client/' + this.$route.query.id)
                .then(response => {
                    this.client = response.data;
                })
            axios.get(this.$API_ADDRESS + '/clientOrder/listByClient/' + this.$route.query.id)
                .then(response => {
                    this.clientOrders = response.data;
                })
        }
    },

    data: function () {
        return {
            client: null,
            clientOrders: [],
            selectedOrders: [],
        }
    },

    methods: {
        orderStatusPt(status) {
            switch (status) {
                case 'RECEIVED':
                    return 'Recebida';
                case 'SEEN':
                    return 'Visto';
                case 'PROCESSED':
                    return 'Processado';
                case 'DELIVERED':
                    return 'Entregue';
                case 'FINISHED':
                    return 'Finalizado';
            }
            return '';
        },

        personTypePt(status) {
            switch (status) {
                case 'FISICAL_PERSON':
                    return 'Pessoa Física';
                case 'LEGAL_PERSON':
                    return 'Pessoa Jurídica';
                case 'FOREIGNER':
                    return 'Estrangeiro';
            }
            return '';
        },
    }
}
</script>

<style scoped>
</style>