<template>
    <div>
        <div style="font-family: sourcesanspro">
            <div class="vCenterWrapper menuBackground" id="headerWrapper">
                <div style="width: 100%; font-size: 0.85rem;" v-if="company">
                    <img :src="$API_ADDRESS + '/download/' + company.image" alt="Logo" style="height: 4rem; width: auto !important; margin: 0 auto;" class="d-block">
                </div>
            </div>
        </div>
        <div class="contentOnBigHeader" style="background-color: #eeeeee; color: #555; padding: 1rem; text-align: center" v-if="company">
            <h4 style="padding-top: 3rem;">{{ company.companyName }}</h4>
            {{ company.socialReason }}<br/><br/>
            <span class="label">Site: </span><a :href="company.site">{{ company.site }}</a><br/>
            <span class="label">Email: </span>{{ company.email }}<br/>
            <span class="label">CNPJ: </span>{{ company.cnpj }}<br/>
            <span class="label">Telefone: </span>{{ company.cellPhone }}<br/>
            <div v-if="company.address">
                <span class="label">Endereço: </span>{{ company.address.street }} - {{ company.address.number }}, {{ company.address.district }}.<br/>
                <span class="label">Cep: </span>{{ company.address.postalCode }}
                <span v-if="company.address.complement">, {{ company.address.complement }}</span><br/>
                <span class="label">Cidade: </span>{{ company.address.city }} - {{ company.address.state }}, {{ company.address.country }}<br/>
            </div>
        </div>

        <Footer/>
    </div>
</template>

<script>
import Footer from '../../template/Footer'
import axios from "axios";

export default {
    components: {
        Footer
    },

    created() {
        if (this.$route.query.id === undefined) {
            this.$router.push({name: '404'})
        }
        axios.get(this.$API_ADDRESS + '/license/' + this.$route.query.id)
            .then(response => {
                this.company = response.data
            })
    },

    data: function () {
        return {
            company: null,
        }
    },

    methods: {}
}
</script>

<style scoped>
#headerWrapper {
    height: 5rem;
    justify-content: center;
}

h3 {
    margin-left: 0.4rem;
    font-size: 2rem;
    font-family: 'righteous';
}
</style>