<template>
    <div>
        <MenuBar/>
        <div class="contentOnNavBar">
            <SideMenu/>
            <div style="padding: 0.5rem; width: 100%">
                <div class="pageTitle">Configurações da Licença</div>
                <div class="pageTitleDetails">Abaixo você encontra as principais configurações de sua licença.</div>

                <form class="row pt-2" style="margin-left: 0; margin-right: 0;" @submit.prevent="saveLicense">
                    <div class="col-sm-6 col-lg-4 col-xl-3 mb-1">
                        <label for="name">Nome da Empresa *</label>
                        <input v-model="license.companyName" type="text" id="name" required class="form-control form-control-sm"/>
                    </div>

                    <div class="col-sm-6 col-lg-4 col-xl-3 mb-1">
                        <label for="email">Email *</label>
                        <input v-model="license.email" type="email" id="email" required class="form-control form-control-sm"/>
                    </div>

                    <div class="col-sm-6 col-lg-4 col-xl-3 mb-1">
                        <label for="socialReason">Razão Social</label>
                        <input v-model="license.socialReason" type="text" id="socialReason" required class="form-control form-control-sm"/>
                    </div>

                    <div class="col-sm-6 col-lg-4 col-xl-3 mb-1">
                        <label for="site">Site</label>
                        <input v-model="license.site" type="text" id="site" required class="form-control form-control-sm"/>
                    </div>

                    <div class="col-sm-6 col-lg-4 col-xl-3 mb-1">
                        <label for="cnpj">CNPJ</label>
                        <input v-model="license.cnpj" v-mask="'##.###.###/####-##'" type="text" id="cnpj" required class="form-control form-control-sm"/>
                    </div>


                    <div>
                        <div class="pageSubTitle mb-3">Endereço</div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-xl-3 mb-1">
                        <label>Logradouro</label>
                        <input v-model="license.address.street" type="text" class="form-control form-control-sm" placeholder="Ex.: Rua Rothbard, Av. Hoppe..."/>
                    </div>
                    <div class="col-sm-6 col-lg-2 col-xl-2 mb-1">
                        <label>Número</label>
                        <input v-model="license.address.number" type="number" class="form-control form-control-sm" placeholder="Ex.: 1971"/>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-xl-3 mb-1">
                        <label>Bairro</label>
                        <input v-model="license.address.district" type="text" class="form-control form-control-sm" placeholder="Ex.: Bairro Milton Friedman"/>
                    </div>
                    <div class="col-sm-6 col-lg-2 col-xl-2 mb-1">
                        <label>CEP</label>
                        <input v-model="license.address.postalCode" type="text" class="form-control form-control-sm" v-mask="'#####-###'"/>
                    </div>
                    <div class="col-12 mb-1">
                        <label>Complemento</label>
                        <input v-model="license.address.complement" type="text" class="form-control form-control-sm"/>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-xl-4 mb-1">
                        <label>Cidade</label>
                        <input v-model="license.address.city" type="text" class="form-control form-control-sm"/>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-xl-4 mb-1">
                        <label>Estado</label>
                        <input v-model="license.address.state" type="text" class="form-control form-control-sm"/>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-xl-4 mb-1">
                        <label>País</label>
                        <input v-model="license.address.country" type="text" class="form-control form-control-sm"/>
                    </div>


                    <div>
                        <div class="pageSubTitle mb-3">Logo</div>
                    </div>
                    <div class="col-12">
                        <div class="input-group mb-3" style="width: 30rem">
                            <input type="file" class="form-control" id="files" @change="onChangeFileUpload($event)" accept="image/*">
                        </div>
                    </div>

                    <div class="col-12" v-if="license.image" style="margin-top: 1rem">
                        <img :src="$API_ADDRESS + '/download/' + license.image" alt="Logo da Licença" style="height: 100px; width: auto !important; margin: 0 auto;" class="d-block">
                    </div>


                    <div>
                        <div class="pageSubTitle mb-3">Mensagens e Alertas</div>
                    </div>
                    <div class="col-sm-6 col-lg-2 col-xl-2 mb-1 pt-2">
                        <input class="form-check-input" type="checkbox" disabled checked>&nbsp;
                        <label class="form-check-label"> Informações do Sistema </label>
                    </div>
                    <div class="col-sm-6 col-lg-2 col-xl-2 mb-1 pt-2">
                        <input class="form-check-input" type="checkbox" checked>&nbsp;
                        <label class="form-check-label"> Novo Pedido </label>
                    </div>


                    <div style="width: 100%; text-align: center; margin-top: 2rem;">
                        <input type="submit" class="btn btn-success" value="Salvar"/>
                    </div>
                </form>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
import MenuBar from '../../template/MenuBar'
import SideMenu from '../../template/SideMenu'
import Footer from '../../template/Footer'
import axios from "axios";

export default {
    components: {
        MenuBar,
        SideMenu,
        Footer,
    },

    mounted() {
        if (axios.defaults.headers.common["Authorization"] == undefined || axios.defaults.headers.common["Authorization"] == '') {
            this.$router.push({name: 'Index'})
        }
    },

    created() {
        this.license = JSON.parse(atob(sessionStorage.loggedUser)).license
    },

    data: function () {
        return {
            license: null,
            licenseLogo: null,
        }
    },

    methods: {
        onChangeFileUpload(e) {
            this.licenseLogo = e.target.files[0];
        },

        saveLicense() {
            let formData = new FormData();
            formData.append("licenseLogo", this.licenseLogo);
            formData.append("body", JSON.stringify(this.license))
            let config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': axios.defaults.headers.common["Authorization"]
                }
            }

            axios.post(this.$API_ADDRESS + '/license/update', formData, config)
                .then(response => {
                    if (response.data) {
                        let loggedUser = JSON.parse(atob(sessionStorage.loggedUser));
                        loggedUser.license = response.data;
                        sessionStorage.setItem('loggedUser', btoa(JSON.stringify(response.data)))
                        this.license = loggedUser.license;
                        this.showMessage("Licença Atualizada!", 'INFO');
                    }
                })
        },
    },
}
</script>

<style scoped>
</style>