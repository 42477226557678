<template>
    <div>
        <MenuBar/>
        <div class="contentOnNavBar">
            <SideMenu/>
            <div style="padding: 0.5em; width: 100%" v-if="clientOrder">
                <div class="pageTitle" v-if="clientOrder.id === 0">Cadastrar Pedido</div>
                <div class="pageTitle" v-if="clientOrder.id !== 0">Atualizar Pedido</div>
                <div class="pageTitleDetails">Insira abaixo as informações do Pedido. Campos marcados com "*" são obrigatórios.</div>

                <form class="row" style="margin-left: 0; margin-right: 0; margin-top: 1rem" @submit.prevent="saveClientOrder">
                    <ClientSelectDialog @client-added="addClientOrder($event)"/>

                    <div style="margin-top: 1em" class="row">
                        <div class="col-sm-12 col-lg-4 col-xl-4 mb-1">
                            <label for="name">Nome do Cliente*</label>
                            <input v-model="clientOrder.client.name" type="text" id="name" required class="form-control form-control-sm"/>
                        </div>

                        <div class="col-sm-12 col-lg-4 col-xl-4 mb-1">
                            <label for="email">Email do Cliente*</label>
                            <input v-model="clientOrder.client.email" type="email" required id="email" class="form-control form-control-sm"/>
                        </div>

                        <div class="col-sm-12 col-lg-4 col-xl-4 mb-1">
                            <label for="celPhone" class="customLabel">Celular do Cliente</label>
                            <input v-model="clientOrder.client.privatePhone" type="text" id="celPhone" class="form-control form-control-sm"
                                   v-mask="'(##) #####-####'"/>
                        </div>

                        <div class="col-12">
                            <label for="observations" class="customLabel">Observações</label>
                            <textarea v-model="clientOrder.observations" type="text" rows="5" id="observations" class="form-control form-control-sm"/>
                        </div>

                        <div class="col-12" style="text-align: center; margin: 0 auto">
                            <label>Status *</label><br/>
                            <SelectClientOrderStatus :name="'orderStatus'" v-model="clientOrder.status" style="width: fit-content"/>
                        </div>
                    </div>


                    <div class="mb-2">
                        <div class="pageSubTitle">Lista de Produtos</div>
                    </div>
                    <AddProductToClientOrder v-on:product-added="addProductToClientOrder"/>


                    <div class="mb-2">
                        <table class="table table-sm">
                            <thead>
                            <tr>
                                <th scope="col">Nome do Produto</th>
                                <th scope="col">Quantidade</th>
                                <th scope="col">Valor Unitário</th>
                                <th scope="col">Subtotal</th>
                                <th scope="col">Ações</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(product, index) in clientOrder.products" :key="index">
                                <td>{{ product.name }}</td>
                                <td>
                                    <input type="number" style="width: 5rem" v-model="product.quantity" v-on:input="getClientOrderTotal()">
                                </td>
                                <td>
                                    R$ <input type="number" style="width: 5rem" v-model="product.price" v-on:input="getClientOrderTotal()">
                                </td>
                                <td>R$ {{ (product.price * product.quantity).toLocaleString() }}</td>
                                <td>
                                    <button class="btn btn-danger btn-sm" style="margin-left: 0.3rem; padding: 0.1rem" @click="removeProductFromOrder(product)"
                                            data-bs-toggle="tooltip" data-bs-placement="bottom" title="Excluir">
                                    <span class="badge ">
                                        <img src="../../../assets/img/trash_24_white.png" height="16" width="auto" class="cursorPointer"
                                             data-bs-toggle="tooltip" data-bs-placement="bottom" title="Excluir"/>
                                    </span>
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <h5 style="text-align: center; margin-top: 0.4rem">Total: R$ {{ clientOrder.total.toLocaleString() }}</h5>
                    </div>

                    <div style="width: 100%; text-align: center">
                        <input type="submit" class="btn btn-success" value="Cadastrar Pedido" v-if="clientOrder.id === 0"/>
                        <input type="submit" class="btn btn-success" value="Atualizar Pedido" v-if="clientOrder.id !== 0"/>
                    </div>
                </form>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
import MenuBar from '../../template/MenuBar'
import SideMenu from '../../template/SideMenu'
import Footer from '../../template/Footer'
import axios from "axios";
import AddProductToClientOrder from "@/components/pages/components/AddProductToClientOrder";
import SelectClientOrderStatus from "@/components/pages/components/SelectClientOrderStatus";
import ClientSelectDialog from "@/components/pages/components/ClientSelectDialog";

export default {
    components: {
        ClientSelectDialog,
        SelectClientOrderStatus,
        AddProductToClientOrder,
        MenuBar,
        SideMenu,
        Footer,
    },

    data: function () {
        return {
            clientOrder: {
                id: 0,
                client: {
                    name: '',
                    email: '',
                    privatePhone: ''
                },
                observations: '',
                products: [],
                total: 0.0,
            },
        }
    },

    mounted() {
        if (axios.defaults.headers.common["Authorization"] == undefined || axios.defaults.headers.common["Authorization"] == '') {
            this.$router.push({name: 'Index'})
        }
    },

    created() {
        if (this.$route.query.id !== undefined) {
            axios.get(this.$API_ADDRESS + '/clientOrder/' + this.$route.query.id)
                .then(response => {
                    this.clientOrder = response.data;
                    this.clientOrder.products.forEach(p => {
                        p.value = p.value.toFixed(2)
                    })
                })
        } else if (this.$route.query.clientId !== undefined) {
            axios.get(this.$API_ADDRESS + '/client/' + this.$route.query.clientId)
                .then(response => {
                    this.clientOrder.client = response.data;
                })
        }
    },

    methods: {
        saveClientOrder() {
            axios.post(this.$API_ADDRESS + '/clientOrder', this.clientOrder)
                .then(response => {
                    this.$router.push({name: 'ClientOrderView', query: {'id': this.clientOrder.id !== 0 ? this.clientOrder.id : response.data.id}})
                })
        },

        addProductToClientOrder(product) {
            if (!this.orderHasProduct(product.id)) {
                product.quantity = 1;
                product.price = product.sellPrice;
                this.clientOrder.products.push(product);
                this.getClientOrderTotal();
            }
        },

        getClientOrderTotal: function () {
            let total = 0.0;
            this.clientOrder.products.forEach(p => {
                total += p.price * p.quantity
            })
            this.clientOrder.total = total
        },

        removeProductFromOrder: function (product) {
            if (this.orderHasProduct(product.id)) {
                this.clientOrder.products.forEach(p => {
                    if (p.id === product.id)
                        this.clientOrder.products.splice(this.clientOrder.products.indexOf(p), 1)
                })
            }
            return this.clientOrder
        },

        orderHasProduct: function (productId) {
            let result = false
            this.clientOrder.products.forEach(p => {
                if (p.id === productId)
                    result = true
            })
            return result
        },

        addClientOrder: function (e) {
            this.clientOrder.client.name = e.name
            this.clientOrder.client.email = e.email
            this.clientOrder.client.privatePhone = e.privatePhone
        },
    }
}


</script>

<style scoped>


</style>