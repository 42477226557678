<template>
    <div>
        <MenuBar/>
        <div class="contentOnNavBar">
            <SideMenu/>
            <div style="padding: 0.5rem; width: 100%">
                <div class="pageTitle">Visualizar Catálogo</div>
                <div class="pageTitleDetails">Abaixo as informações do Catálogo.</div>

                <div v-if="catalog" style="padding: 0.5rem;">

                    <span class="label">Nome:</span> {{ catalog.name }}<br/>
                    <span class="label">Descrição:</span> {{ catalog.introduction }}<br/>
                    <span class="label">Link:</span> {{ catalog.link }}<br/>
                    <span class="label">Número de Visualizações:</span> {{ catalog.viewCount }}<br/>
                    <span class="label">Data de Criação:</span> {{ catalog.date | moment("DD/MM/YYYY HH:mm") }}<br/>

                    <div class="pageSubTitle mb-2">Produtos do Catálogo</div>
                    <table class="table table-sm" style="padding-top: 0.5rem;" v-if="catalog">
                        <thead>
                        <tr>
                            <th scope="col">Nome do Produto</th>
                            <th scope="col">Preço de Venda</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="catalogProduct in catalog.products" :key="catalogProduct.id">
                            <td class="align-middle">{{ catalogProduct.name }}</td>
                            <td class="align-middle">R$ {{ catalogProduct.sellPrice.toLocaleString() }}</td>
                        </tr>
                        </tbody>
                    </table>

                    <div style="margin: 0 auto; width: 100%; text-align: center">
                        <div class="btn-group " role="group">
                            <button id="btnGroupDrop1" type="button" class="btn btn-primary btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                Ações
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="btnGroupDrop1" style="min-width: unset !important; padding: 0 !important">
                                <li><a class="dropdown-item sm" @click="$router.push({name: 'CatalogCreate', query:{'id': catalog.id}})" style="cursor: pointer"> <i class="fas fa-pen" style="margin-right: 0.5rem"></i>Editar</a></li>
                                <li><a class="dropdown-item sm" @click="$refs.shareDialog.urlToShare = $APP_URL + '/catalogView?id='+catalog.id" data-bs-toggle="modal" data-bs-target="#shareChooseDialog" style="cursor: pointer;"><i class="fas fa-share-alt" style="margin-right: 0.5rem"></i>Compartilhar</a></li>
                                <li><a class="dropdown-item sm" @click="downloadPDF(catalog.id)" style="cursor: pointer"> <i class="fas fas fa-file-download" style="margin-right: 0.5rem"></i>Baixar PDF</a></li>
                                <li><a class="dropdown-item sm" data-bs-toggle="modal" data-bs-target="#confirmDelete" style="cursor: pointer"> <i class="fas fa-trash" style="margin-right: 0.5rem"></i>Excluir</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <ConfirmDelete v-on:done="$router.push({name: 'Catalogs'})" :objects-to-exclude="[catalog.id]"
                           :message="'Tem certeza que deseja excluir o catálogo selecionado?'" :endpoint="'/catalog'"/>
            <ShareChooseDialog ref="shareDialog"/>
        </div>
        <Footer/>
    </div>
</template>

<script>
import MenuBar from '../../template/MenuBar'
import SideMenu from '../../template/SideMenu'
import Footer from '../../template/Footer'
import axios from "axios";
import ConfirmDelete from "@/components/pages/components/ConfirmDelete";
import ShareChooseDialog from "../components/ShareChooseDialog";

export default {
    components: {
        ConfirmDelete,
        MenuBar,
        SideMenu,
        Footer,
        ShareChooseDialog
    },

    created() {
        if (this.$route.query.id !== '') {
            axios.get(this.$API_ADDRESS + '/catalog/' + this.$route.query.id)
                .then(response => {
                    this.catalog = response.data;
                })
                .catch(e => {
                    this.showMessage(e.data, 'ERROR');
                })
        }
    },

    data: function () {
        return {
            catalog: null
        }
    },

    mounted() {
        if (axios.defaults.headers.common["Authorization"] == undefined || axios.defaults.headers.common["Authorization"] == '') {
            this.$router.push({name: 'Index'})
        }
    },

    methods: {
        downloadPDF(catalogId) {
            let headers = {}
            headers.Authorization = axios.defaults.headers.common["Authorization"]
            headers.responseType = 'blob'
            axios.get(this.$API_ADDRESS + '/catalog/catalogPdf/' + catalogId, headers)
                .then(response => {
                    let blob = new Blob([response.data], {
                        type: 'application/pdf'
                    });
                    window.open(window.URL.createObjectURL(blob));
                })
        },
    }
}
</script>

<style scoped>
</style>