<template>
    <div style="display: inline-block">
        <div id="carouselExampleIndicators" class="carousel carousel-dark slide" data-bs-ride="carousel"
             style="max-height: 300px; width: 100%; margin: 0 auto;" v-if="imagesList.length > 0">

            <div class="carousel-indicators" v-if="imagesList.length > 0">
                <button type="button" data-bs-target="#carouselExampleIndicators"
                        v-for="(img, index) in imagesList" :key="index"
                        :data-bs-slide-to="index" :class="selectedIndex == index ? 'active' : ''"
                        :aria-current="selectedIndex == index ? 'true' : ''" :aria-label="'Slide ' + (index + 1)"></button>
            </div>

            <div class="carousel-inner" style="height: 300px;">
                <div v-for="(img, index) in imagesList" :key="index" :class="selectedIndex == index ? 'carousel-item active' : 'carousel-item'">
                    <img :src="$API_ADDRESS + '/download/' + img" alt="Imagem do Produto" style="height: 300px; width: auto !important; margin: 0 auto;" class="d-block">
                </div>
            </div>

            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev" v-if="imagesList.length > 1">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Anterior</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next" v-if="imagesList.length > 1">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Próxima</span>
            </button>
        </div>
    </div>
</template>

<script>

export default {
    name: "ImageCarousel",

    props: {
        imagesList: Array
    },

    data: function () {
        return {
            selectedIndex: 0
        }
    }
}
</script>

<style scoped>

</style>