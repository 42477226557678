<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>

import axios from "axios";

if (axios.defaults.headers.common["Authorization"] == undefined) {
    if (sessionStorage.Authorization !== undefined) {
        axios.defaults.headers.common["Authorization"] = sessionStorage.Authorization
    }
}


export default {
    name: "App",

    mounted() {
        axios.interceptors.request.use(req => {
            this.turnLoaderOn();
            return req;
        });

        axios.interceptors.response.use(
            res => {
                this.turnLoaderOff();
                return res;
            },
            error => {
                this.turnLoaderOff();
                if (error.response.data.status !== undefined) {
                    this.showMessage('Erro ' + error.response.data.status + ': ' + error.response.data.message, 'ERROR');
                } else {
                    this.showMessage(error.response.data, 'ERROR');
                }
                return Promise.reject(error);
            }
        );
    },


};

</script>

<style>
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css");

body {
    padding: 0;
    margin: 0;
}

@font-face {
    font-family: "righteous";
    src: url(assets/font/Righteous.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "roboto";
    src: url(assets/font/Roboto-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "sourcesanspro";
    src: url(assets/font/SourceSansPro-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "sourcesanspro";
    src: url(assets/font/SourceSansPro-Bold.ttf) format('truetype');
    font-weight: bold;
}

a {
    text-decoration: none;
    color: inherit;
}

.vCenterWrapper {
    width: 100%;
    color: white;
    display: flex;
    align-items: center;
    font-family: "righteous";
    justify-content: center;
}

.contentOnBigHeader {
    width: 100%;
    min-height: calc(100vh - 6.2rem);
    height: calc(100vh - 8.5rem);
    overflow: auto;
    color: black;
}

.contentOnNavBar {
    width: 100%;
    min-height: calc(100vh - 50px - 1.2rem);
    display: flex;
    background-color: rgb(236, 240, 245);
}

.pageTitle {
    border-bottom: 1px solid #ccc;
    font-size: 1.4rem;
    font-family: var(--bs-font-sans-serif);
    height: fit-content;
    width: 100%;
    display: block;
    color: #333333;
}

.pageTitleDetails {
    padding-left: 0.5rem;
    color: #888;
    font-family: sourcesanspro;
    font-size: 0.9rem;
}

.pageSubTitle {
    border-bottom: 1px solid #ccc;
    font-size: 1.2rem;
    font-family: 'Verdana';
    height: fit-content;
    width: 100%;
    display: block;
    color: #666;
    margin-top: 1rem;
}

.carousel-control-next, .carousel-control-prev {
    border: none;
    background-color: transparent;
}

.label {
    font-weight: bold;
    color: #555;
}

label {
    font-weight: bold;
    font-size: 0.9rem;
}

.cursorPointer {
    cursor: pointer;
}

.bg-image {
    background: rgb(121, 185, 186);
    background: radial-gradient(circle, rgba(230, 230, 230, 1) 0%, rgba(160, 160, 160, 1) 100%);
    background-size: cover;
    margin: 0 auto;
}

@media only screen and (max-width: 800px) {

    /* Force table to not be like tables anymore */
    #no-more-tables table,
    #no-more-tables thead,
    #no-more-tables tbody,
    #no-more-tables th,
    #no-more-tables td,
    #no-more-tables tr {
        display: block;
    }

    #no-more-tables tr {
        margin-bottom: 0.5rem;
        border-bottom: 1px solid #ccc;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    #no-more-tables thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    #no-more-tables td {
        /* Behave  like a "row" */
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        padding-left: 27%;
        white-space: normal;
        text-align: left;
    }

    #no-more-tables td:before {
        /* Now like a table header */
        position: absolute;
        /* Top/left values mimic padding */
        top: 6px;
        left: 6px;
        width: 22%;
        padding-right: 10px;
        white-space: nowrap;
        text-align: left;
        font-weight: bold;
    }

    /*
    Label the data
    */
    #no-more-tables td:before {
        content: attr(data-title);
    }
}
</style>
