<template>
    <div style="display: inline-block">
        <!-- Modal -->
        <div class="modal fade" id="confirmDelete" tabindex="-1" aria-labelledby="exampleModalLabel23" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <form class="modal-content" @submit.prevent="">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel23">Confirmar Exclusão</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Fechar"></button>
                    </div>
                    <div class="modal-body" v-if="message">
                        {{ objectsToExclude.length > 1 ? 'Tem certeza que deseja excluir ' + objectsToExclude.length + ' objetos?' : message }}
                    </div>
                    <div class="modal-footer">
                        <div style="width: 100%">
                            <button type="button" class="btn btn-danger" data-bs-dismiss="modal" @click="deleteObjects()" style="float: left">Sim</button>
                            <button type="button" class="btn btn-success" data-bs-dismiss="modal" style="float: right">Não</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>

import axios from "axios";

export default {

    props: {
        objectsToExclude: {
            type: Array
        },
        message: null,
        endpoint: null
    },

    methods: {
        deleteObjects() {
            axios.delete(this.$API_ADDRESS + this.endpoint + '?idList=' + encodeURI(JSON.stringify(this.objectsToExclude)))
                .then(() => {
                    this.showMessage(this.objectsToExclude.length > 1 ? 'Itens Excluídos' : 'Item excluído', 'INFO');
                    this.$emit('done')
                })
        }
    }

}
</script>

<style scoped>

</style>