<template>
    <div>
        <MenuBar/>
        <div class="contentOnNavBar">
            <SideMenu/>
            <div style="padding: 0.5rem; width: 100%">
                <div class="pageTitle">Administração do Sistema</div>

                <div style="padding: 1rem 0 0.5rem 0; width: 100%;">
                    <button class="btn btn-primary btn-sm" @click="$router.push({name: 'LicenseCreate'})">Nova Licença</button>
                    <button class="btn btn-danger btn-sm" style="float: right;" >Excluir Selecionados</button>
                </div>

                <table class="table table-sm" style="padding-top: 0.5rem;">
                    <thead>
                    <tr>
                        <th scope="col">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox">
                            </div>
                        </th>
                        <th scope="col">Id</th>
                        <th scope="col">Empresa</th>
                        <th scope="col">Email</th>
                        <th scope="col">Status</th>
                        <th scope="col">Validade</th>
                        <th scope="col" style="width: 5.7rem">Ações</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(license, index) in licenses" :key="index">
                        <th scope="row" class="align-middle">
                            <div class="form-check">
                                <input class="form-check-input" :value="license.id" type="checkbox" v-model="selectedLicenses">
                            </div>
                        </th>
                        <td class="align-middle">{{ license.id }}</td>
                        <td class="align-middle">{{ license.companyName }}</td>
                        <td class="align-middle">{{ license.email }}</td>
                        <td class="align-middle"></td>
                        <td class="align-middle"></td>
                        <td class="align-middle">
                            <div class="btn-group " role="group">
                                <button id="btnGroupDrop1" type="button" class="btn btn-warning btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                    Ações
                                </button>
                                <ul class="dropdown-menu" aria-labelledby="btnGroupDrop1" style="min-width: unset !important; padding: 0 !important">
                                    <li><a class="dropdown-item sm" @click="$router.push({name: 'LicenseCreate', query: {'id': license.id}})" style="cursor: pointer">
                                        <i class="fas fa-pen fa-sm" style="margin-right: 0.5rem"></i>Editar</a>
                                    </li>
                                    <li><a class="dropdown-item sm" data-bs-toggle="modal" data-bs-target="#confirmDelete" style="cursor: pointer">
                                        <i class="fas fa-trash fa-sm" style="margin-right: 0.5rem"></i>Excluir</a></li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <Pagination v-on:page-clicked="(c) => this.licenses = c" endpoint-list="/admin/list/" endpoint-count="/admin/count"/>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
import MenuBar from '../../template/MenuBar'
import SideMenu from '../../template/SideMenu'
import Footer from '../../template/Footer'
import Pagination from "@/components/pages/components/Pagination";
import axios from "axios";

export default {
    components: {
        Pagination,
        MenuBar,
        SideMenu,
        Footer
    },

    mounted() {
        if (axios.defaults.headers.common["Authorization"] == undefined || axios.defaults.headers.common["Authorization"] == '') {
            this.$router.push({name: 'Index'})
        }
    },

    created() {

    },

    data: function () {
        return {
            licenses: [],
            selectedLicenses: []
        }
    },
}
</script>

<style scoped>
</style>