<template>
    <div style="display: inline-block">
        <!-- Button trigger modal -->
        <button type="button" class="btn btn-primary btn-sm " data-bs-toggle="modal" data-bs-target="#selectClientDialog">Selecionar Cliente</button>

        <!-- Modal -->
        <div class="modal fade" id="selectClientDialog" tabindex="-1" aria-labelledby="selectClientDialogLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <form class="modal-content" @submit.prevent="">
                    <div class="modal-header">
                        <h5 class="modal-title" id="selectClientDialogLabel">Selecionar Cliente</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                    <div class="modal-body">
                        <div class="mb-2">
                            <label for="searchProd" class="form-label">Nome ou email do cliente:</label>
                            <div class="d-flex">
                                <input v-model="description" type="text" id="searchProd" placeholder="Pelo menos 3 caracteres..."
                                       required class="form-control form-control-sm rounded-0 rounded-start">
                                <div class="btn-sm btn-secondary rounded-0 rounded-end" type="button" @click="findClientByDescription">
                                    <i class="fas fa-search"/>
                                </div>
                            </div>
                        </div>
                        <div class="mb-2" style="max-height: 380px; overflow-y: scroll">
                            <div class="row" v-for="client in foundClients" :key="client.id" style="display: inline;">
                                <div class="col-12">
                                    <button data-bs-dismiss="modal" type="submit" class="btn btn-sm btn-primary" style="width: auto" v-on:click="$emit('client-added', client)"
                                            data-bs-toggle="tooltip" data-bs-placement="bottom" title="Selecionar">+
                                    </button>
                                    {{ client.name }} (<b style="padding: 0;">{{ client.email }}</b>)
                                    <br/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id="cancelBtn">Fechar</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>

import axios from "axios";

export default {

    data: function () {
        return {
            description: null,
            foundClients: [],
        }
    },

    methods: {
        findClientByDescription() {
            if (this.description.length >= 3) {
                axios.get(this.$API_ADDRESS + '/client/getByDescription?description=' + this.description, null)
                    .then(response => {
                        this.foundClients = response.data;
                    })
            }
        },

    }

}
</script>

<style scoped>

</style>