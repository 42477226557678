<template>
    <div>
        <MenuBar/>
        <div class="contentOnNavBar">
            <SideMenu/>
            <div style="padding: 0.5rem; width: 100%">
                <div class="pageTitle" v-if="license.id === 0">Cadastrar Licença</div>
                <div class="pageTitle" v-if="license.id !== 0">Atualizar Licença</div>
                <div class="pageTitleDetails">Insira abaixo as informações da Licença. Campos marcados com "*" são obrigatórios.</div>

                <form class="row pt-3" style="margin-left: 0; margin-right: 0;" @submit.prevent="saveLicense">

                    <div class="col-sm-12 col-lg-1 col-xl-1 mb-1">
                        <label>Id</label>
                        <input v-model="license.id" disabled type="text" required class="form-control form-control-sm"/>
                    </div>

                    <div class="col-sm-12 col-lg-6 col-xl-4 mb-1">
                        <label>Nome da Empresa *</label>
                        <input v-model="license.companyName" type="text" required class="form-control form-control-sm"/>
                    </div>

                    <div class="col-sm-6 col-lg-5 col-xl-3 mb-1">
                        <label class="customLabel">CNPJ</label>
                        <input type="text" class="form-control form-control-sm" v-model="license.cnpj"/>
                    </div>

                    <div class="col-sm-6 col-lg-6 col-xl-4 mb-1">
                        <label class="customLabel">Email *</label>
                        <input type="text" class="form-control form-control-sm" v-model="license.email"/>
                    </div>

                    <div class="col-sm-6 col-lg-6 col-xl-3 mb-1">
                        <label class="customLabel">Razão Social</label>
                        <input type="text" class="form-control form-control-sm" v-model="license.socialReason"/>
                    </div>

                    <div class="col-sm-6 col-lg-6 col-xl-3 mb-1">
                        <label class="customLabel">Site</label>
                        <input type="text" class="form-control form-control-sm" v-model="license.site"/>
                    </div>

                    <div class="col-sm-6 col-lg-6 col-xl-3 mb-1">
                        <label class="customLabel">Status *</label>
                        <input type="text" class="form-control form-control-sm" v-model="license.status"/>
                    </div>

                    <div class="col-sm-6 col-lg-6 col-xl-3 mb-1">
                        <label class="customLabel">Validade *</label>
                        <input type="text" class="form-control form-control-sm" v-model="license.validUntil"/>
                    </div>

                    <div class="mb-2">
                        <div class="pageSubTitle">Endereço</div>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-xl-3 mb-1">
                        <label>Logradouro</label>
                        <input v-model="license.address.street" type="text" class="form-control form-control-sm" placeholder="Ex.: Rua Rothbard, Av. Hoppe..."/>
                    </div>
                    <div class="col-sm-6 col-lg-2 col-xl-2 mb-1">
                        <label>Número</label>
                        <input v-model="license.address.number" type="number" class="form-control form-control-sm" placeholder="Ex.: 1971"/>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-xl-3 mb-1">
                        <label>Bairro</label>
                        <input v-model="license.address.district" type="text" class="form-control form-control-sm" placeholder="Ex.: Bairro Milton Friedman"/>
                    </div>
                    <div class="col-sm-6 col-lg-2 col-xl-2 mb-1">
                        <label>CEP</label>
                        <input v-model="license.address.postalCode" type="text" class="form-control form-control-sm" v-mask="'#####-###'"/>
                    </div>
                    <div class="col-12 mb-1">
                        <label>Complemento</label>
                        <input v-model="license.address.complement" type="text" class="form-control form-control-sm"/>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-xl-4 mb-1">
                        <label>Cidade</label>
                        <input v-model="license.address.city" type="text" class="form-control form-control-sm"/>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-xl-4 mb-1">
                        <label>Estado</label>
                        <input v-model="license.address.state" type="text" class="form-control form-control-sm"/>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-xl-4 mb-1">
                        <label>País</label>
                        <input v-model="license.address.country" type="text" class="form-control form-control-sm"/>
                    </div>

                    <div class="mb-2">
                        <div class="pageSubTitle">Módulos</div>
                    </div>
                    <div class="col-sm-6 col-lg-2 col-xl-2 mb-1 pt-2">
                        <input class="form-check-input" type="checkbox" :checked="checkLicenseModule('CATALOG')" @click="addOrRemoveModule('CATALOG')">&nbsp;
                        <label class="form-check-label"> Catálogo </label>
                    </div>
                    <div class="col-sm-6 col-lg-2 col-xl-2 mb-1 pt-2">
                        <input class="form-check-input" type="checkbox" :checked="checkLicenseModule('INVENTORY')" @click="addOrRemoveModule('INVENTORY')">&nbsp;
                        <label class="form-check-label"> Estoque </label>
                    </div>
                    <div class="col-sm-6 col-lg-2 col-xl-2 mb-1 pt-2">
                        <input class="form-check-input" type="checkbox" :checked="checkLicenseModule('FINANCIAL')" @click="addOrRemoveModule('FINANCIAL')">
                        <label class="form-check-label"> Financeiro </label>
                    </div>
                    <div class="col-sm-6 col-lg-2 col-xl-2 mb-1 pt-2">
                        <input class="form-check-input" type="checkbox" :checked="checkLicenseModule('REPORT')" @click="addOrRemoveModule('REPORT')">
                        <label class="form-check-label"> Relatórios </label>
                    </div>
                    <div class="col-sm-6 col-lg-2 col-xl-2 mb-1 pt-2">
                        <input class="form-check-input" type="checkbox" :checked="checkLicenseModule('INVOICE')" @click="addOrRemoveModule('INVOICE')">
                        <label class="form-check-label"> Notas Fiscais </label>
                    </div>
                    <div class="col-sm-6 col-lg-2 col-xl-2 mb-1 pt-2">
                        <input class="form-check-input" type="checkbox" :checked="checkLicenseModule('SUBSIDIARIES')" @click="addOrRemoveModule('SUBSIDIARIES')">
                        <label class="form-check-label"> Filiais </label>
                    </div>
                    <div class="col-sm-6 col-lg-2 col-xl-2 mb-1 pt-2">
                        <input class="form-check-input" type="checkbox" :checked="checkLicenseModule('CALENDAR')" @click="addOrRemoveModule('CALENDAR')">
                        <label class="form-check-label"> Agenda </label>
                    </div>
                    <div class="col-sm-6 col-lg-2 col-xl-2 mb-1 pt-2">
                        <input class="form-check-input" type="checkbox" :checked="checkLicenseModule('EMPLOYEES')" @click="addOrRemoveModule('EMPLOYEES')">
                        <label class="form-check-label"> Funcionários </label>
                    </div>

                    <div style="width: 100%; text-align: center; margin-top: 1rem">
                        <button class="btn btn-success mt-2" v-if="license.id === 0">Cadastrar Licença</button>
                        <button class="btn btn-success mt-2" v-if="license.id !== 0">Atualizar Licença</button>
                    </div>
                </form>

            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
import MenuBar from '../../template/MenuBar'
import SideMenu from '../../template/SideMenu'
import Footer from '../../template/Footer'
import axios from "axios";

export default {
    components: {
        MenuBar,
        SideMenu,
        Footer,
    },

    data: function () {
        return {
            license: {
                id: 0,
                companyName: null,
                cnpj: null,
                email: null,
                address: {
                    id: 0,
                    type: null,
                    postalCode: null,
                    street: null,
                    number: 0,
                    complement: null,
                    district: null,
                    city: null,
                    state: null,
                    country: null
                },
                socialReason: null,
                site: null,
                cellPhone: null,
                status: null,
                systemModules: [],
                validUntil: null,
            },
        }
    },

    mounted() {
        if (axios.defaults.headers.common["Authorization"] == undefined || axios.defaults.headers.common["Authorization"] == '') {
            this.$router.push({name: 'Index'})
        }

        if (this.$route.query.id !== undefined) {
            axios.get(this.$API_ADDRESS + '/license/' + this.$route.query.id)
                .then(response => {
                    this.license = response.data
                })
        }
    },

    methods: {
        saveLicense: function () {
            axios.post(this.$API_ADDRESS + '/license', this.license)
                .then(response => {
                    this.$router.push({name: 'CatalogViewInternal', query: {'id': this.catalog.id !== 0 ? this.catalog.id : response.data.id}})
                })
        },

        addOrRemoveModule(module) {
            if (this.license.systemModules.indexOf(module) < 0) {
                this.license.systemModules.push(module)
            } else {
                this.license.systemModules.splice(this.license.systemModules.indexOf(module), 1)
            }
        },

        checkLicenseModule(module) {
            return this.license.systemModules.indexOf(module) >= 0
        },
    }
}


</script>

<style scoped>


</style>